import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { countryCodes, countryCodesDictionary, regionDictionary, dummyCategories, dummyWeeklyDeal } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Proton from "assets/javascript/proton";

import Breadcrumb from "components/breadcrumb"
import Sidebar from "components/account/sidebar";

function Dashboard() {
    const navigate = useNavigate();
    const [slidePosition, setPosition] = useState(0);
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({ listing: { scrollTop: 0, scrollBottom: 3, option0: [], option1: [], option2: [], buttonClass: "", isWatched: false, sellerKYC: false, deliveryLocation: "", deliveryCost: "", locationsString: "", locationsMore: false, locationsOverflow: false } });
    const [data, setData] = useState({ categories: dummyCategories, listing: dummyWeeklyDeal });
    const [widgets, setWidgets] = useState([]);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) {
            dispatch({
                type: "promptLogin",
                value: "/account"
            });
            
            return navigate("/");
        };
    }, [state.authenticated]);

    useEffect(() => {
        // fetch account data from server
            setTimeout(() => {
                setData(values => (
                    {
                        ...values,
                        categories: dummyCategories,
                        listing: dummyWeeklyDeal
                    }
                ));
            }, 200)

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    function handleLogout() {
        if (!state.link || !state.session) return;

        state.link.removeSession("Storex", state.session.auth).then(res => {
            dispatch({
                type: "setAuth",
                value: null
            });

            dispatch({
                type: "setWallet",
                value: null
            });
        });
    };

    const breadcrumbs = [
        { label: "Home", path: "/" },
        { label: textStrings["my_account"][state.language], path: "/account" }
    ]

    return (
        <section className="account">
            <div className="page-header">My Account</div>

            <div className="container">
                <div className="breadcrumb-wrapper border-bottom">
                    <div className="breadcrumb">
                        <Breadcrumb crumbs={breadcrumbs} />
                    </div>
                </div>

                <div className="account-wrapper">
                    <Sidebar setWidgets={setWidgets} />

                    <div className="tile-wrapper">
                        <div className="account-overview">
                            <div className="important"><i className="fal fa-exclamation-triangle"></i> Your account has been suspended, please contact support to resolve the issue</div>

                            { (state.account.fullName != "") ? <p>Welcome back <span>{ state.account.fullName }</span></p> : <p>Welcome to your account dashboard</p> }

                            <div className="notifications">You have <Link to="/notifications"><span>{ state.account?.notifications?.reduce((a, b) => a = a + (!b.markedRead), 0) }</span></Link> new notification{ (state.account?.notifications?.reduce((a, b) => a = a + (!b.markedRead), 0) > 1) ? "s" : "" }</div>
                        </div>

                        <div className="tiles">
                            {
                                widgets.map((tile, index) => (
                                    <Link key={index} to={tile.link} className={`tile${tile.disabled ? " soon" : ""}`}>
                                        <i className={tile.icon}></i>
                                        <span>{tile.title}</span>
                                        <p>{tile.description}</p>
                                        {
                                            (tile.disabled) && (
                                                <div className="soon">Coming Soon</div>
                                            )
                                        }
                                    </Link>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Dashboard;
