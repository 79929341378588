import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import Cookies from "js-cookie";

import { useStateValue } from "state";

import * as Proton from "assets/javascript/proton";
import * as Account from "assets/javascript/account";

function UserAgreement() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <section className="user-agreement container">
            <div className="page-title">User Agreement</div>

            <div className="intro">
                <div className="last-updated">Last updated April 29th, 2022</div>

                <div className="pre">A reference in this User Agreement to "we", "us", or "our" means Storex Marketplace Ltd.</div>

                <div className="pre">This User Agreement, the Privacy Notice, and the Cookie Notice posted on our sites set out the terms on which we offer you access to and use of our websites, services, applications, and tools (collectively "Services").</div>

                <div className="pre">You agree to comply with all of the above when accessing or using our Services. We reserve the right to update or amend this User Agreement at any time. You should check this page from time to time to make sure you are aware of any changes.</div>

                <div className="pre">For information regarding the usage of internet cookies and the processing of personal data, please view our <Link to="/privacy-notice">Privacy Notice</Link>.</div>
            </div>

            <div className="section">
                <div className="title">WEBSITE CONTENT</div>

                <div className="content">
                    The content provided by us on this website is owned or controlled by us and may be protected by copyright laws in the United Kingdom and overseas. You may only use the content for your own personal purposes, and may not otherwise use, copy, reproduce, modify, publish, or transmit the content without our prior written consent.
                    <br/><br/>
                    We use reasonable endeavours to ensure that the information we include on this website is accurate and up-to-date. However, to the extent permitted by law, we cannot guarantee the accuracy, currency or completeness of the information provided and we accept no responsibility for errors in the content at any time.
                    <br/><br/>
                    We do not guarantee that use of our website will be uninterrupted or error-free.
                    <br/><br/>
                    To the extent permitted by law, all liability is excluded for any direct loss, indirect loss, damages, or injury resulting from your access to, or inability to access, this website, or as a consequence of any content or information obtained from or through this website.
                    <br/><br/>
                    This website includes trademarks which are protected by law. You may not use our trademarks in the United Kingdom or internationally without our prior written consent, except to legitimately identify our Services.
                    <br/><br/>
                    You may link to this website provided you do so in a way that accurately indicates that the link is to a Storex Marketplace Ltd website and is not misleading.
                </div>
            </div>

            <div className="section">
                <div className="title">THIRD-PARTY WEBSITES</div>

                <div className="content">
                    This website may contain links or references to third-party websites. We are not responsible or liable for the content of those websites and your access to, and use of, those websites is at your own risk. Any links are provided for convenience only, and do not indicate any endorsement or recommendation by us.
                </div>
            </div>

            <div className="section">
                <div className="title">MISUSE OF WEBSITE</div>

                <div className="content">
                    You agree to only use this website in accordance with this User Agreement and applicable laws. You may be held personally liable for any unlawful use of this website. If you think any content on this website is inappropriate, unlawful, or infringes your rights, please contact us at <a href="mailto:contact@storex.io" target="_blank">contact@storex.io</a>.
                </div>
            </div>

            <div className="section">
                <div className="title">GOVERNING LAW</div>

                <div className="content">
                    This User Agreement will be governed by and construed in accordance with the laws of the United Kingdom. Courts of the United Kingdom shall have exclusive jurisdiction to settle any disputes or claims which shall arise from this agreement.
                    <br/><br/>
                    Although this website may be accessed outside of the United Kingdom, we make no representation that the content complies with the law of any other country. If you access this website from outside of the United Kingdom, you are solely responsible for ensuring compliance with your local laws and for any reliance on our website content. If your local laws do not permit you to access our Services, then you must not access them.
                </div>
            </div>

            <div className="section">
                <div className="title">SEVERABILITY</div>

                <div className="content">
                    Any part, provision, representation or warranty of this User Agreement which is prohibited or which is held to be void or unenforceable shall be ineffective, to the extent permitted by applicable law, without invalidating the remaining provisions.
                </div>
            </div>
        </section>
    );
};

export default UserAgreement;
