import { defaultAccount, countryCodes, countryCodesDictionary, regionDictionary, dummyCategories, dummyWeeklyDeal } from "constants";

function Address({ type, addresses, handleEditAddress, handleDeleteAddress, handleNewAddress }) {

    return (
        <ul>
            {
                addresses?.map((address, index) => (
                    <li key={index}>
                        <div className="bold">{address.fullName}</div>
                        <div>
                            {address.addressLine1}
                            {
                                (address.addressLine2) && (
                                    <span>, {address.addressLine2}</span>
                                )
                            }
                        </div>
                        <div>{address.cityTown}, {address.state}</div>
                        <div>{address.zipcode}</div>
                        <div>{countryCodesDictionary[address.country]}</div>

                        <div className="actions">
                            <button onClick={(e) => handleEditAddress(e.target, type, address)}>Edit</button>
                            <button onClick={(e) => handleDeleteAddress(e.target, type, address._id)}>Delete</button>
                        </div>
                    </li>
                ))
            }

            <li className="add-new" onClick={() => handleNewAddress(type)}>
                <i className="fal fa-plus"></i>
                <div>Add Address</div>
            </li>
        </ul>
    );
};

export default Address;
