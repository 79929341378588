import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";
import PropTypes from 'prop-types';

import { useStateValue } from "state";
import { dummyCategories } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Proton from "assets/javascript/proton";

function Sidebar({ setWidgets }) {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({ newsletter: "" });
    const [data, setData] = useState({ categories: {} });
    const [state, dispatch] = useStateValue();

    useEffect(() => {
        if (setWidgets) {
            setWidgets(dashboardWidgets);
        }
    }, [setWidgets]);

    useEffect(() => {
        $(".sidebar-wrapper").children("[href='" + window.location.pathname + "']").addClass("active");
    }, []);

    function handleLogout() {
        if (!state.link || !state.session) return;

        state.link.removeSession("Storex", state.session.auth).then(res => {
            dispatch({
                type: "setAuth",
                value: null
            });

            dispatch({
                type: "setWallet",
                value: null
            });
        });
    };

    const dashboardWidgets = [
        { title: "Settings", icon: "fal fa-user-cog", link: "/account/settings", description: state.w3aConnected ? "Manage your account details, identity verification, private keys, and sessions" : "Manage your account details and sessions" },
        { title: "Wallet", icon: "fal fa-wallet", link: "/account/wallet", description: state.w3aConnected ? "Manage your Storex wallet, crypto assets, and NFTs" : "Manage your external WebAuth wallet and token balances" },
        { title: "Addresses", icon: "fal fa-map-marker-alt", link: "/account/addresses", description: "Update your registered address, billing address for invoices, and delivery address for goods" },
        { title: "Order History", icon: "fal fa-bags-shopping", link: "/account/orders", description: "Manage your current orders and view your purchase history" },
        { title: "Active Listings", icon: "fal fa-tags", link: "/account/listings", description: "Manage your active listings" },
        { title: "Sales", icon: "fal fa-box-usd", link: "/account/sales", description: "Manage your sold listings" },
        { title: "Storefront", icon: "fal fa-store", link: "/profile/" + state.session.auth.actor, description: "Manage and customize your storefront" },
        { title: "Staking", icon: "fal fa-piggy-bank", link: "/account/staking", description: "Manage your staking balances and enroll in fee reductions" },
        { title: "Arbitration", icon: "fal fa-gavel", link: "/account/arbitration", description: "Manage your disputes and participate as a community arbitrator" },
        { title: "Governance", icon: "fal fa-box-ballot", link: "", description: "View governance proposals and participate as a councillor", disabled: true },
        { title: "Vouchers", icon: "fal fa-gift-card", link: "/account/vouchers", description: "Buy and manage gift vouchers and active promotions" },
        { title: "Premium", icon: "fal fa-star", link: "", description: "Manage your Premium status and view your benefits", disabled: true },
        { title: "Ads", icon: "fal fa-ad", link: "", description: "Manage and review your ongoing ad campaigns", disabled: true },
    ]

    return (
        <div className="sidebar-wrapper n600">
            <Link to="/account">Dashboard</Link>
            {
                dashboardWidgets.filter(link => !link.disabled).map((link, index) => (
                    <Link key={index} to={link.link}>{link.title}</Link>
                ))
            }
            <Link to="/" onClick={ handleLogout }>Sign Out</Link>
        </div>
    );
};

Sidebar.propTypes = {
    setWidgets: PropTypes.func,
};

export default Sidebar;
