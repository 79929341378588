import $ from "jquery";
import Cookies from "js-cookie";
import { apiEndpoint, defaultGenCoin } from "constants";

export function verifyWallet(username) {
    if (!username) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "GET",
        url: apiEndpoint + "/auth/verify",
        data: {
            user: username,
        },
        error: function(res) {
            if (res.status === 0) return $("div.error-modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");

            var { message } = res.responseJSON;

            $("div.error-modal").addClass("error").find("div.text").html(message);
        }
    });
};

export function requestLogin(username, publicToken, privateToken, serializedTransaction, signatures, signer, resolvedTransaction) {
    if (!username || !publicToken || !privateToken || !serializedTransaction || !signatures || !signer) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/auth/verify",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: username,
            publicToken: publicToken,
            privateToken: privateToken,
            serializedTransaction: JSON.stringify(serializedTransaction),
            signatures: JSON.stringify(signatures),
            signer: JSON.stringify(signer),
            resolvedTransaction: JSON.stringify(resolvedTransaction || {})
        },
        error: function(res) {
            if (res.status === 0) return $("div.error-modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");

            var { message } = res.responseJSON;

            $("div.error-modal").addClass("error").find("div.text").html(message);
        }
    });
};

export function requestToken(username) {
    if (!username) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/auth/token",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: username
        },
        error: function(res) {
            if (res.status === 0) $("div.error-modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");

            if (res.status === 503) $("div.error-modal").addClass("error fixed").find("div.text").html("The server is down for maintenance, try again later");
        }
    });
};

export function fetchAccountDetails(user) {
    if (!user) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "GET",
        url: apiEndpoint + "/account/details",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: user
        },
        error: function(res) {
            if (res.status === 0) return $("div.error-modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

export function updateAccountDetails(user, inputs) {
    if (!user || !inputs) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/account/update",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: user,
            avatar: inputs.avatar || "/assets/images/default_avatar.webp",
            displayName: inputs.displayName || null,
            fullName: inputs.fullName || null,
            dateBirth: inputs.dateBirth || null,
            email: inputs.email || null,
            callingCode: inputs.callingCode || null,
            phoneNumber: inputs.phoneNumber || null,
            country: inputs.country || null,
            referral: inputs.referral || null
        },
        error: function(res) {
            if (res.status === 0) return $("div.error-modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

export function terminateSession(user, sessionId) {
    if (!user || !sessionId) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/account/session/terminate",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: user,
            sessionId: sessionId
        },
        error: function(res) {
            if (res.status === 0) return $("div.error-modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

export function updateRegisteredAddress(user, inputs) {
    if (!user || !inputs) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/account/regAddress/update",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: user,
            addressLine1: inputs.regAddress.addressLine1 || null,
            addressLine2: inputs.regAddress.addressLine2 || null,
            cityTown: inputs.regAddress.cityTown || null,
            state: inputs.regAddress.state || null,
            zipcode: inputs.regAddress.zipcode || null,
            country: inputs.regAddress.country || null
        },
        error: function(res) {
            if (res.status === 0) return $("div.error-modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

export function addAddress(user, inputs, addressType) {
    if (!user || !inputs || !addressType) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/account/addAddress",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: user,
            addressType: addressType,
            fullName: inputs.newAddress.fullName || null,
            addressLine1: inputs.newAddress.addressLine1 || null,
            addressLine2: inputs.newAddress.addressLine2 || null,
            cityTown: inputs.newAddress.cityTown || null,
            state: inputs.newAddress.state || null,
            zipcode: inputs.newAddress.zipcode || null,
            country: inputs.newAddress.country || null
        },
        error: function(res) {
            if (res.status === 0) return $("div.error-modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

export function editAddress(user, inputs, addressType, addressId) {
    if (!user || !inputs || !addressType || !addressId) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/account/editAddress",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: user,
            addressType: addressType,
            addressId: addressId,
            fullName: inputs.editAddress.fullName || null,
            addressLine1: inputs.editAddress.addressLine1 || null,
            addressLine2: inputs.editAddress.addressLine2 || null,
            cityTown: inputs.editAddress.cityTown || null,
            state: inputs.editAddress.state || null,
            zipcode: inputs.editAddress.zipcode || null,
            country: inputs.editAddress.country || null
        },
        error: function(res) {
            if (res.status === 0) return $("div.error-modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

export function deleteAddress(user, inputs, addressType, addressId) {
    if (!user || !inputs || !addressType || !addressId) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/account/deleteAddress",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: user,
            addressType: addressType,
            addressId: addressId
        },
        error: function(res) {
            if (res.status === 0) return $("div.error-modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

export function generateDepositAddress(coin, wallet, signer, signatures, transaction) {
    if (!coin || !signer || !signatures || !transaction) return Promise.reject("missing_parameters");

    if (wallet?.includes("?")) {
        var [, defaultNetwork] = wallet.split("?");
        
        [coin, wallet] = defaultGenCoin[defaultNetwork].split("-");
    };
    
    return $.ajax({
        type: "POST",
        url: "https://www.api.bloks.io/proton-wrap-public2/address/generate",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify({
            coin: coin,
            wallet: (wallet ? coin + "-" + wallet : coin),
            signer: signer,
            signatures: signatures,
            transaction: transaction
        }),
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

export function fetchSellerListings(user) {
    if (!user) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "GET",
        url: apiEndpoint + "/account/listings",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: user
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

export function fetchSalesListings(user) {
    if (!user) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "GET",
        url: apiEndpoint + "/account/sales",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: user
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

export function fetchOrderListings(user) {
    if (!user) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "GET",
        url: apiEndpoint + "/account/purchases",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: user
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

export function fetchMessages(user) {
    if (!user) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "GET",
        url: apiEndpoint + "/account/messages",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: user
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

export function sendMessage(user, recipient, content, button) {
    if (!user || !recipient || !content) return;

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/account/message",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: user,
            recipient: recipient,
            content: content
        },
        beforeSend: function() {
            $(button).css("color", "grey");
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

export function notificationRead(username, notificationId) {
    if (!username || !notificationId) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/account/notifications/read",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: username,
            notificationId: notificationId
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

export function notificationReadAll(username) {
    if (!username) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/account/notifications/readAll",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: username
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

export function notificationDelete(username, notificationId) {
    if (!username || !notificationId) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/account/notifications/delete",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: username,
            notificationId: notificationId
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

export function notificationDeleteAll(username) {
    if (!username) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/account/notifications/deleteAll",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: username
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

//

export function register(inputs) {
    if (!inputs) return Promise.reject("missing_parameters");

    if (inputs.password !== inputs.checkPassword) {
        $("button.register").html("Register");
        $(".form-alert").addClass("active").children(".message").html("The passwords do not match, please try again");
        return Promise.reject("passwords_do_not_match");
    };

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/account/register",
        data: {
            username: inputs.username,
            email: inputs.email,
            password: inputs.password,
            countryCode: inputs.countryCode,
            phone: inputs.phone,
            referral: inputs.referral
        },
        error: function(res) {
            if (res.status === 0) {
                $(".form-alert").addClass("active").children(".message").html("There was an error sending your request, please try again");
                return;
            };

            var { message } = res.responseJSON;
            $(".form-alert").addClass("active").children(".message").html(message);
        },
        complete: function() {
            $("button.register").html("Register");
        }
    });
};

export function verifyEmail(accountId, verificationCode) {
    if (!accountId || !verificationCode) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/account/verify/email",
        data: {
            accountId: accountId,
            verificationCode: verificationCode
        },
        error: function(res) {
            if (res.status === 0) {
                $(".form-alert").addClass("active").children(".message").html("There was an error sending your request, please try again");
                return;
            };

            var { message } = res.responseJSON;
            $(".form-alert").addClass("active").children(".message").html(message);
        }
    });
};

export function resendVerification(accountId) {
    if (!accountId) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/account/verify/email/resend",
        data: {
            accountId: accountId
        },
        error: function(res) {
            if (res.status === 0) {
                $(".form-alert").addClass("active").children(".message").html("There was an error sending your request, please try again");
                return;
            };

            var { data } = res.responseJSON;

            if (data) {
                var secondsLeft = Math.ceil((data - Date.now()) / 1000);
                $("button.resend").html("Resend in " + secondsLeft + " seconds").addClass("disabled").attr("disabled", true);

                const resendInterval = setInterval(function() {
                    if (secondsLeft === 0) {
                        clearInterval(resendInterval);
                        return $("button.resend").html("Resend").removeClass("disabled").attr("disabled", false);
                    };

                    secondsLeft -= 1;

                    $("button.resend").html("Resend in " + secondsLeft + " seconds").addClass("disabled").attr("disabled", true);
                }, 1000);
            };
        },
        complete: function() {
            //$("button.resend").html("Resend");
        }
    });
};

export function login(inputs) {
    if (!inputs) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/auth/login",
        data: {
            __rt: Cookies.get("__rt"),
            email: inputs.email,
            password: inputs.password
        },
        error: function(res) {
            if (res.status === 0) {
                $(".form-alert").addClass("active").children(".message").html("There was an error sending your request, please try again");
                return;
            };

            var { message, code } = res.responseJSON;

            if (code === "email_not_verified") return;

            $(".form-alert").addClass("active").children(".message").html(message);
        },
        complete: function() {
            $("button.login").html("Login");
        }
    });
};

export function logout() {
    if (!Cookies.get("__rt")) return Promise.reject("no_refresh_token");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/auth/logout",
        data: {
            __rt: Cookies.get("__rt")
        },
        error: function(res) {
            if (res.status === 0) return console.log(res);
        },
        complete: function() {
            Cookies.remove("__rt", { path: "/", domain: ".storex.io" });
        }
    });
};