import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import Cookies from "js-cookie";

import { useStateValue } from "state";

import * as Proton from "assets/javascript/proton";
import * as Account from "assets/javascript/account";

function PrivacyNotice() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <section className="user-agreement container">
            <div className="page-title">Privacy Notice</div>

            <div className="intro">
                <div className="last-updated">Last updated April 29th, 2022</div>

                <div className="pre">This Privacy Notice concerns any personal information of yours which is provided to us.</div>

                <div className="pre">Personal information is any information about an identified individual or an individual who is reasonably identifiable, whether the information is true or not and whether the information is recorded in a material form or not.</div>

                <div className="pre">We collect, use, and disclose personal information in accordance with this Privacy Notice and the Data Protection Act 2018.</div>
            </div>

            <div className="section">
                <div className="title">PERSONAL INFORMATION COLLECTION</div>

                <div className="content">
                    The types of personal information we may collect include:
                    <br/><br/>
                    <ul>
                        <li>from users who create a Storex account – email addresses, names, usernames, phone numbers, and information about a user’s response time. For tax purposes, we will also collect information about whether you are a United Kingdom resident;</li>
                        <li>from website visitors – IP addresses, website preferences, traffic sources and device information;</li>
                        <li>if you contact us with a query – your contact details.</li>
                    </ul>
                    <br/><br/>
                    If we are not provided with the information that we request, then we may not be able to provide services to you or respond to your query.
                    <br/><br/>
                    The personal information you provide us may be used for a number of purposes connected with our business operations, which include to:
                    <br/><br/>
                    <ul>
                        <li>provide our services, including facilitating communications between Storex users;</li>
                        <li>respond to any requests from you;</li>
                        <li>develop and improve the quality and scope of the services we provide, and seek your feedback;</li>
                        <li>store choices you previously have made on the site, including when you are not logged in, to improve your experience;</li>
                        <li>better understand how our website visitors use the website and how Storex users use the service; and</li>
                        <li>send you marketing messages about our products and services.</li>
                    </ul>
                    <br/><br/>
                    We may send you messages by email, SMS, or push notification, with updates about our services, news related to cryptocurrency or decentralised finance, and marketing messages. You always have the right to opt-out of receiving such information. You may exercise that right by clicking the "unsubscribe" link in a message we send you, or contacting us using the details below.
                    <br/><br/>
                    We will not use your information for purposes other than those described above unless we have your consent or as permitted by law (including for law enforcement or public health and safety reasons).
                </div>
            </div>

            <div className="section">
                <div className="title">PASSIVE INFORMATION COLLECTION</div>

                <div className="content">
                    As you navigate through this website, certain information can be passively collected (that is, gathered without you actively providing the information) through various technologies, such as cookies, internet tags or web beacons and navigational data collection. You can manage passive information collection settings in the settings of your browser.
                    <br/><br/>
                    This website may use and combine such passively collected information to provide better services to website visitors, customise the website based on your preferences, compile and analyse statistics and trends and otherwise administer and improve the website for your use.
                </div>
            </div>

            <div className="section">
                <div className="title">HOW WE COLLECT PERSONAL INFORMATION</div>

                <div className="content">
                    We generally collect personal information directly from you, for example through online forms or when you contact us.
                    <br/><br/>
                    We may also collect personal information through trade activity, server log files and passive information collection technologies.
                </div>
            </div>

            <div className="section">
                <div className="title">SHARING YOUR PERSONAL INFORMATION</div>

                <div className="content">
                    We also may disclose your personal information to:
                    <br/><br/>
                    <ul>
                        <li>others in accordance with a request made by you – for example, if your username is your real name, then we will list this when you ask to be listed on the Storex marketplace;</li>
                        <li>our third party service providers engaged by us to perform business and technology services, when reasonably required;</li>
                        <li>law enforcement bodies – both if required by a warrant or voluntarily if we suspect you have engaged in fraud or misuse; and</li>
                        <li>persons to whom we are required by law to disclose information.</li>
                    </ul>
                    <br/><br/>
                    When making disclosures to our service providers, we take reasonable steps to ensure that they are bound by privacy obligations.
                    <br/><br/>
                    We may also disclose limited information about your connection to Storex to other users you have directly interacted with for the purpose of assisting them in minimising risks – including the country your IP address is associated with, and whether your IP address is deemed to be associated with an anonymising network tunnelling service, such as an open proxy server, Tor exit node, or a Virtual Private Network service.
                    <br/><br/>
                    Unless you consent, we otherwise do not disclose your personal information to third parties.
                </div>
            </div>

            <div className="section">
                <div className="title">PERSONAL INFORMATION STORAGE</div>

                <div className="content">
                    We will take all reasonable precautions to safeguard your information from loss, misuse, unauthorised access, modification, disclosure or destruction. We may store your files as electronic records. We implement a range of electronic security measures to protect the personal information that we hold, including using secured databases using industry-standard network security practices.
                    <br/><br/>
                    You should keep in mind that no internet transmission is ever completely secure or error-free.
                    <br/><br/>
                    We will only send your personal information outside the United Kingdom:
                    <br/><br/>
                    <ul>
                        <li>if we are authorised to do so by law;</li>
                        <li>for any of the purposes set out in this Privacy Notice (but only to parties that are subject to obligations in relation to personal information no less onerous than those in this Privacy Notice); or</li>
                        <li>if you have consented to us doing so.</li>
                    </ul>
                    <br/><br/>
                    You can request to delete your personal information by using the "delete account" feature.
                    <br/><br/>
                    After you delete your account, most information we have collected about you will be deleted or anonymized within 180 days, however we may retain some information for up to 5 years following the date of your request.
                    <br/><br/>
                    We may retain your personal information for a longer period only if there is a legal obligation for us to do so.
                </div>
            </div>

            <div className="section">
                <div className="title">ACCESSING YOUR PERSONAL INFORMATION</div>

                <div className="content">
                    You may request access to any of the personal information we hold about you by contacting us as specified below. We reserve the right to charge a reasonable fee for the costs of retrieval and supply of any requested information.
                    <br/><br/>
                    We will take all reasonable steps to ensure that the personal information we collect, use or disclose is accurate, complete and up to date. To ensure your personal information is accurate, please notify us of any errors or changes to your personal information and we will take appropriate steps to update or correct such information in our possession.
                </div>
            </div>

            <div className="section">
                <div className="title">EXTERNAL LINKS</div>

                <div className="content">
                    This website may contain links or references to other websites to which this Privacy Notice may not apply. You should check their own privacy policies before providing your personal information.
                </div>
            </div>

            <div className="section">
                <div className="title">NOTIFYING DATA BREACHES</div>

                <div className="content">
                    In the event of any loss or unauthorised access or disclosure of your personal information that is likely to result in serious harm to you, we will investigate and notify you and the Information Commissioner’s Office as soon as practicable, in accordance with the Data Protection Act 2018.
                </div>
            </div>

            <div className="section">
                <div className="title">COMPLAINTS</div>

                <div className="content">
                    If you have any questions or concerns about our collection, use or disclosure of personal information, or if you believe we have not complied with this Privacy Notice or the Data Protection Act 2018, please contact us as set out below. We will investigate the complaint and determine whether a breach has occurred and what action, if any, to take.
                    <br/><br/>
                    We take all privacy complaints seriously and will aim to resolve any such complaint in a timely and efficient manner.
                    <br/><br/>
                    We expect our procedures will deal fairly and promptly with your complaint. However, if you remain dissatisfied, you can also make a formal complaint with the Information Commissioner’s Office at <a href="https://ico.org.uk/" target="_blank">ico.org.uk</a>.
                </div>
            </div>

            <div className="section">
                <div className="title">HOW TO CONTACT US</div>

                <div className="content">
                    If you wish to exercise your right to opt-out of receiving our marketing materials, or you have any questions or concerns about this Privacy Notice or our information practices, please contact us at <a href="mailto:contact@storex.io" target="_blank">contact@storex.io</a>.
                </div>
            </div>
        </section>
    );
};

export default PrivacyNotice;
