import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { countryCodes, countryCodesDictionary, regionDictionary, dummyCategories, dummyWeeklyDeal, dummyListings } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Cart from "assets/javascript/cart";
import * as Proton from "assets/javascript/proton";

function Disabled() {
    const navigate = useNavigate();
    const [slidePosition, setPosition] = useState(0);
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({ prices: {}, payment: "", listing: { scrollTop: 0, scrollBottom: 3, variation: -1, option0: [], option1: [], option2: [], buttonClass: "", isCarted: false, isWished: false, sellerKYC: false, deliveryLocation: "", deliveryCost: "", locationsString: "", locationsMore: false, locationsOverflow: false } });
    const [data, setData] = useState({ categories: dummyCategories, listing: dummyWeeklyDeal, dummyListings: dummyListings });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <section className="error">
            <div className="page-header">Page Disabled</div>

            <div className="container">
                <i className="fal fa-toggle-off grey"></i>

                <div className="description">The page you are looking for has been disabled in this version</div>

                <Link to="/"><button>Back to Homepage</button></Link>
            </div>
        </section>
    );
};

export default Disabled;
