import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ crumbs }) => {
    if (!crumbs || crumbs.length === 0) {
        return null;
    }

    const [first, ...rest] = crumbs;

    return (
        <>
            <Link to={first.path}>{first.label}</Link>
            {rest.length > 0 && rest[0].label && (
                <>
                    <span> &gt; </span>
                    <Breadcrumb crumbs={rest} />
                </>
            )}
        </>
    );
};

export default Breadcrumb;