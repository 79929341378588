import $ from "jquery";
import Cookies from "js-cookie";
import { apiEndpoint } from "constants";

export function addListing(username, listingId, variation, quantity) {
    if (!username) return Promise.reject({ responseJSON: { message: "no auth" } });
    if (!listingId || !quantity) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/account/wishlist/add",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: username,
            listingId: listingId,
            variation: variation,
            quantity: quantity
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};

export function removeListing(username, listingId, variation, quantity) {
    if (!username) return Promise.reject({ responseJSON: { message: "no auth" } });
    if (!listingId || !quantity) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/account/wishlist/remove",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: username,
            listingId: listingId,
            variation: variation,
            quantity: quantity
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};

export function clearWishlist(username) {
    if (!username) return Promise.reject({ responseJSON: { message: "no auth" } });

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/account/wishlist/clear",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: username
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};

export function fetchListingDetails(listingIds) {
    if (listingIds.length < 1) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "GET",
        url: apiEndpoint + "/listing/info",
        xhrFields: {
            withCredentials: true
        },
        data: {
            listingIds: listingIds
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};