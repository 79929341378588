import { defaultAccount, countryCodes, countryCodesDictionary, regionDictionary, dummyCategories, dummyWeeklyDeal } from "constants";
import { textStrings } from "localization";

function RegisteredAddress({ handleRegisteredAddress, handleChange, inputs, setInputs, state }) {

    return (
        <form onSubmit={handleRegisteredAddress}>
            <div className="input-group">
                <label htmlFor="regAddress-addressLine1">Address Line 1</label>
                <input type="text" name="regAddress-addressLine1" autoComplete="address-line1" value={inputs?.regAddress?.addressLine1 || ""} onChange={handleChange} aria-label="Address line 1" required />
            </div>

            <div className="input-group">
                <label htmlFor="regAddress-addressLine2">Address Line 2 <span className="optional">Optional</span></label>
                <input type="text" name="regAddress-addressLine2" autoComplete="address-line2" value={inputs?.regAddress?.addressLine2 || ""} onChange={handleChange} aria-label="Address line 2" />
            </div>

            <div className="input-group">
                <label htmlFor="regAddress-cityTown">City/Town</label>
                <input type="text" name="regAddress-cityTown" autoComplete="address-level2" value={inputs?.regAddress?.cityTown || ""} onChange={handleChange} aria-label="Town" required />
            </div>

            <div className="input-group">
                <label htmlFor="regAddress-state">County</label>
                <input type="text" name="regAddress-state" autoComplete="address-level1" value={inputs?.regAddress?.state || ""} onChange={handleChange} aria-label="County" required />
            </div>

            <div className="input-group">
                <label htmlFor="regAddress-zipcode">Postcode</label>
                <input type="text" name="regAddress-zipcode" autoComplete="postal-code" value={inputs?.regAddress?.zipcode || ""} onChange={handleChange} aria-label="Postcode" required />
            </div>

            <div className="input-group">
                <label htmlFor="regAddress-country">Country</label>
                <i className="fal fa-chevron-down"></i>
                <select className="country" name="regAddress-country" autoComplete="country" value={inputs?.regAddress?.country || ""} onChange={handleChange} aria-label="Country" required>
                    <option value="" style={{ display: "none" }}></option>
                    {
                        countryCodes.map((country, index) => (
                            <option key={index} value={country.countryCode}>{country.name}</option>
                        ))
                    }
                </select>
            </div>

            <button type="submit" className="submit reg-address">{textStrings["save_changes"][state.language]}</button>
        </form>
    );
};

export default RegisteredAddress;
