import { Link } from "react-router-dom"

export default function CategoryFeature(props) {
    return (
        <div className="category-feature" style={{ backgroundImage: `url(${props.image})` }}>
            <div className="feature-subtitle" dangerouslySetInnerHTML={{ __html: props.subtitle }}/>
            <div className="feature-title">{props.title}</div>
            <Link to={props.link}>
                <div className="feature-button">Browse Products</div>
            </Link>
        </div>
    )
}