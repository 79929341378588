import $ from "jquery";
import Cookies from "js-cookie";
import { apiEndpoint } from "constants";

export function fetchListing(user, listingId) {
    if (!listingId) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "GET",
        url: apiEndpoint + "/listing",
        data: {
            user: user || "",
            listingId: listingId,
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");

            var { message } = res.responseJSON;

            $("div.modal").addClass("error").find("div.text").html(message);
        }
    });
};

export function searchListings(searchTerm) {
    if (!searchTerm) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "GET",
        url: apiEndpoint + "/listing/search",
        data: {
            searchTerm: searchTerm,
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};

export function fetchRecentlyViewed(user) {
    return $.ajax({
        type: "GET",
        url: apiEndpoint + "/account/recentlyViewed",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: user
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};

export function fetchWeeklyDeal() {
    return $.ajax({
        type: "GET",
        url: apiEndpoint + "/listing/weeklyDeal",
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");

            var { message } = res.responseJSON;

            $("div.modal").addClass("error").find("div.text").html(message);
        }
    });
};

export function fetchFeatured() {
    return $.ajax({
        type: "GET",
        url: apiEndpoint + "/listing/featured",
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};

export function fetchBestSellers(quantity) {
    if (quantity == null) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "GET",
        url: apiEndpoint + "/listing/bestSellers",
        data: {
            quantity: quantity,
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");

            var { message } = res.responseJSON;

            $("div.modal").addClass("error").find("div.text").html(message);
        }
    });
};

export function fetchCategoryListings(categoryId, subcategoryId, quantity) {
    if (!categoryId || quantity == null) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "GET",
        url: apiEndpoint + "/listing/category",
        data: {
            categoryId: "category_" + categoryId.replaceAll("-", "_"),
            subcategoryId: subcategoryId ? "sub_" + subcategoryId.replaceAll("-", "_") : "",
            quantity: quantity,
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");

            var { message } = res.responseJSON;

            $("div.modal").addClass("error").find("div.text").html(message);
        }
    });
};

export function createListing(sellerId, txId, inputs, domesticShipping, internationalShipping) {
    if (!sellerId || !txId || !inputs || !domesticShipping || !internationalShipping) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/listing/create",
        xhrFields: {
            withCredentials: true
        },
        data: {
            txId: txId,
            user: sellerId,
            title: inputs.title,
            category: inputs.category,
            subcategory: inputs.subcategory,
            condition: inputs.condition,
            description: inputs.description,
            options: JSON.stringify(inputs.options),
            variations: JSON.stringify(inputs.variations),
            escrow: inputs.escrow,
            payments: JSON.stringify(inputs.currencies),
            location: inputs.location,
            domesticShipping: JSON.stringify(domesticShipping),
            internationalShipping: JSON.stringify(internationalShipping)
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};

export function fetchTemplates(user) {
    if (!user) return Promise.reject("user: Undefined");

    return $.ajax({
        type: "GET",
        url: apiEndpoint + "/listing/templates",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: user
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");

            var { message } = res.responseJSON;

            $("div.modal").addClass("error").find("div.text").html(message);
        }
    });
};

export function saveTemplate(user, listingTemplate) {
    if (!user) return Promise.reject("user: Undefined");
    if (!listingTemplate) return Promise.reject("listingTemplate: Undefined");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/listing/template/save",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: user,
            listingTemplate: listingTemplate
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");

            var { message } = res.responseJSON || { message: res };

            $("div.modal").addClass("error").find("div.text").html(message);
        }
    });
};

export function uploadImages(sellerId, txId, images) {
    if (!sellerId || !txId || !images) return Promise.reject("missing_parameters");

    var formData = new FormData();

    images?.forEach((img, index) => {
        formData.append("file", img);
    });

    formData.append("user", sellerId);
    formData.append("txId", txId);

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/listing/images",
        xhrFields: {
            withCredentials: true
        },
        data: formData,
        processData: false,
        contentType: false,
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};

export function refundOrder(sellerId, orderId, refundFiat, refundCrypto, txId) {
    if (!sellerId || !orderId || !refundFiat || !refundCrypto || !txId) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/listing/refund",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: sellerId,
            orderId: orderId,
            refundFiat: refundFiat,
            refundCrypto: refundCrypto,
            txId: txId
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};

export function refundOrderManual(sellerId, orderId, refundFiat, refundCrypto, txId) {
    if (!sellerId || !orderId || !refundFiat || !refundCrypto || !txId) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/listing/refundManual",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: sellerId,
            orderId: orderId,
            refundFiat: refundFiat,
            refundCrypto: refundCrypto,
            txId: txId
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};

export function endListing(sellerId, listingId, txId) {
    if (!sellerId || !listingId || !txId) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/listing/end",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: sellerId,
            listingId: listingId,
            txId: txId
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};

export function markDispatch(sellerId, orderId, dispatched) {
    if (!sellerId || !orderId || dispatched == null) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/listing/dispatch",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: sellerId,
            orderId: orderId,
            dispatched: dispatched
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};

export function claimFunds(sellerId, orderId, txId) {
    if (!sellerId || !orderId || !txId) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/listing/claim",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: sellerId,
            orderId: orderId,
            txId: txId
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};

export function extendEscrow(buyerId, orderId, txId) {
    if (!buyerId || !orderId || !txId) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/listing/extend",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: buyerId,
            orderId: orderId,
            txId: txId
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};

export function completeOrder(buyerId, orderId, txId) {
    if (!buyerId || !orderId || !txId) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/listing/complete",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: buyerId,
            orderId: orderId,
            txId: txId
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};

export function startDispute(username, orderId, serializedTransaction, signatures, signer, resolvedTransaction, images, description) {
    if (!username) return Promise.reject("username: Undefined");
    if (!orderId) return Promise.reject("orderId: Undefined");
    if (!serializedTransaction) return Promise.reject("serializedTransaction: Undefined");
    if (!signatures) return Promise.reject("signatures: Undefined");
    if (!signer) return Promise.reject("signer: Undefined");

    var formData = new FormData();

    images?.forEach((img, index) => {
        formData.append("file", img);
    });

    formData.append("user", username);
    formData.append("orderId", orderId);
    formData.append("serializedTransaction", JSON.stringify(serializedTransaction));
    formData.append("signatures", JSON.stringify(signatures));
    formData.append("signer", JSON.stringify(signer));
    formData.append("resolvedTransaction", JSON.stringify(resolvedTransaction || {}));
    formData.append("description", description);

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/listing/dispute/start",
        xhrFields: {
            withCredentials: true
        },
        data: formData,
        processData: false,
        contentType: false,
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};

export function uploadEvidence(username, disputeId, files, description) {
    if (!username) return Promise.reject("username: Undefined");
    if (!disputeId) return Promise.reject("disputeId: Undefined");

    if (!files && !description) return Promise.reject("Nothing to upload");;

    var formData = new FormData();

    files?.forEach((img, index) => {
        formData.append("file", img);
    });

    formData.append("user", username);
    formData.append("disputeId", disputeId);
    formData.append("description", description);

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/listing/dispute/upload",
        xhrFields: {
            withCredentials: true
        },
        data: formData,
        processData: false,
        contentType: false,
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};

export function cancelDispute(username, disputeId, serializedTransaction, signatures, signer, resolvedTransaction) {
    if (!username) return Promise.reject("username: Undefined");
    if (!disputeId) return Promise.reject("disputeId: Undefined");
    if (!serializedTransaction) return Promise.reject("serializedTransaction: Undefined");
    if (!signatures) return Promise.reject("signatures: Undefined");
    if (!signer) return Promise.reject("signer: Undefined");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/listing/dispute/cancel",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: username,
            disputeId: disputeId,
            serializedTransaction: JSON.stringify(serializedTransaction),
            signatures: JSON.stringify(signatures),
            signer: JSON.stringify(signer),
            resolvedTransaction: JSON.stringify(resolvedTransaction || {})
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};

export function leaveReview(username, orderId, rating, comments) {
    if (!username) return Promise.reject("username: Undefined");
    if (!orderId) return Promise.reject("orderId: Undefined");
    if (rating == null) return Promise.reject("rating: Undefined");
    if (!comments) return Promise.reject("comments: Undefined");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/listing/review/create",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: username,
            orderId: orderId,
            rating: rating,
            comments: comments
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};

export function fetchDisputes(user) {
    if (!user) return Promise.reject("user: Undefined");

    return $.ajax({
        type: "GET",
        url: apiEndpoint + "/listing/disputes",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: user
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");

            var { message } = res.responseJSON;

            $("div.modal").addClass("error").find("div.text").html(message);
        }
    });
};

export function fetchCases(user, disputeIds) {
    if (!user) return Promise.reject("user: Undefined");
    if (!disputeIds) return Promise.reject("disputeIds: Undefined");

    return $.ajax({
        type: "GET",
        url: apiEndpoint + "/listing/cases",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: user,
            disputeIds: disputeIds
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");

            var { message } = res.responseJSON;

            $("div.modal").addClass("error").find("div.text").html(message);
        }
    });
};

export function purchaseListing(buyerId, txId, listings, deliveryAddress, orderNotes) {
    if (!buyerId || !txId || !listings || !deliveryAddress) return Promise.reject("missing_parameters");

    var syntaxCheck = listings.every(l => {
        if (!l.id) return false;

        if (l.variationId == null) return false;

        return true;
    });

    if (!syntaxCheck) return Promise.reject("listings_syntax_error");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/listing/purchase",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: buyerId,
            txId: txId,
            listings: JSON.stringify(listings),
            deliveryAddress: JSON.stringify(deliveryAddress),
            orderNotes: orderNotes
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
        }
    });
};