import { React, useState, useEffect, useMemo, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { countryCodes, countryCodesDictionary, regionDictionary, dummyCategories, dummyWeeklyDeal, dummyListings, filterPriceIntervals, filterConditions, filterStock, filterCurrencies, filterSellerKYC, filterBuyerKYC, filterShips } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Cart from "assets/javascript/cart";
import * as Proton from "assets/javascript/proton";

import Breadcrumb from "components/breadcrumb"

function Search() {
    const isInitialRender = useRef(true);
    const inputRef = useRef(null);
    var { searchTerm } = useParams();

    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({ showFilters: false, selectedPage: 0, proposedPage: 1, sortBy: "recommended", show: 10, filterPriceMaximum: 0, filterPrice: { min: 0, max: 1000 }, filterCondition: [], filterStock: "", filterCurrencies: [], filterSellerKYC: "", filterBuyerKYC: "", filterShips: "" });
    const [data, setData] = useState({ categories: dummyCategories, rawListings: [], showListings: [], listings: [], listingsKYC: [], deliveryLocation: "", listingsEmpty: null });

    useEffect(() => {
        setData(values => ({
            ...values,
            rawListings: [],
            showListings: [],
            listings: [],
            listingsEmpty: null
        }));

        Listing.searchListings(searchTerm || "").then(res => {
            res = res.filter(l => l.active);
            const highestPrice = Math.max(...res.map(l => parseFloat(l.price.replace(/[^0-9.]/g, ''))));

            setInputs(inputs => ({
                ...inputs,
                filterPriceMaximum: highestPrice,
                filterPrice: {
                    min: inputs.filterPrice.min,
                    max: highestPrice
                }
            }));

            setData(values => ({
                ...values,
                rawListings: res,
                showListings: res.slice(0, inputs.show),
                listings: res,
                listingsEmpty: (res.length < 1)
            }));
        });

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, [searchTerm]);

    useEffect(() => {
        var filteredListings = data.rawListings;

        if (inputs.filterPrice) {
            const { min, max } = inputs.filterPrice;

            filteredListings = filteredListings.filter(listing => parseFloat(listing.price) >= min && parseFloat(listing.price) <= max);
        };

        if (inputs.filterCondition.length > 0) {
            filteredListings = filteredListings.filter(listing => inputs.filterCondition.includes(listing.condition));
        };

        if (inputs.filterStock) {
            if (inputs.filterStock == "In Stock") {
                filteredListings = filteredListings.filter(listing => listing.variations.some(v => v.stock > 0));
            } else {
                filteredListings = filteredListings.filter(listing => listing.variations.every(v => v.stock < 1));
            };
        };

        if (inputs.filterCurrencies.length > 0) {
            filteredListings = filteredListings.filter(listing => listing.currencies.some(currency => inputs.filterCurrencies.includes(currency.name)));
        };

        if (inputs.filterSellerKYC) {
            if (inputs.filterSellerKYC == "Yes") {
                filteredListings = filteredListings.filter(listing => listing.seller?.kyc);
            } else {
                filteredListings = filteredListings.filter(listing => !listing.seller?.kyc);
            };
        };

        if (inputs.filterBuyerKYC) {
            if (inputs.filterBuyerKYC == "Yes") {
                filteredListings = filteredListings.filter(listing => listing.requiresKYC);
            } else {
                filteredListings = filteredListings.filter(listing => !listing.requiresKYC);
            };
        };

        if (inputs.filterShips) {
            if (inputs.filterShips == "Yes") {
                filteredListings = filteredListings.filter(listing => {
                    if (data.deliveryLocation == listing.location) return true;

                    if (!listing.postage?.international?.available?.[data.deliveryLocation]) return false;

                    return true;
                });
            } else {
                filteredListings = filteredListings.filter(listing => {
                    if (data.deliveryLocation == listing.location) return false;

                    if (!listing.postage?.international?.available?.[data.deliveryLocation]) return true;

                    return false;
                });
            };
        };

        filteredListings = sortListings(filteredListings, inputs.sortBy);

        setInputs(values => ({
            ...values,
            selectedPage: 0,
            proposedPage: 1
        }));

        setData(values => ({
                ...values,
                showListings: filteredListings.slice(0 * inputs.show, (0 + 1) * inputs.show),
                listings: filteredListings
        }));
    }, [inputs.show, inputs.sortBy, data.rawListings, inputs.filterPrice, inputs.filterCondition, inputs.filterStock, inputs.filterCurrencies, inputs.filterSellerKYC, inputs.filterBuyerKYC, inputs.filterShips]);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated || state.authenticated && state.account?.deliveryAddress?.[0]?.country == null) return determineLocation();

        setLocation(state.account?.deliveryAddress?.[0]?.country);
    }, [state.account]);

    useEffect(() => {
        if (data.deliveryLocation != "") {
            console.log("calc", data.deliveryLocation)
        };
    }, [data.deliveryLocation]);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
        } else {
            $("body, html").animate({ scrollTop: $("ul.listings").offset().top - 64 }, 0);
        }
    }, [inputs.selectedPage]);

    function sortListings(listings, order) {
        var sortedListings = listings.toSorted((a, b) => {
            const priceA = parseFloat(a.price)
            const priceB = parseFloat(b.price)

            if (order === "lowtohigh") {
                return priceA - priceB;
            } else if (order === "hightolow") {
                return priceB - priceA;
            } else if (order === "mostsales") {
                return b.stats.sales - a.stats.sales;
            } else {
                return 0;
            };
        });

        return sortedListings;
    };

    function determineLocation() {
        const f = async () => {
            var req = await fetch("https://ipinfo.io/json?token=d637d120b18ed1");
            var data = await req.json();

            if (data.hasOwnProperty("country")) {
                if (regionDictionary[data.region]) {
                    setLocation(regionDictionary[data.region]);
                } else {
                    setLocation(data.country);
                };
            };
        };

        f();
    };

    function setLocation(countryCode) {
        if (!countryCode) return;

        setData(values => ({
            ...values,
            deliveryLocation: countryCode
        }));
    };

    function handlePageFocus() {
        inputRef.current.focus();
    };

    function handleKeypress(e) {
        if (e.key == "Enter") {
            handleInputBlur();
        };
    };

    function handleShippingCost(postage) {
        var [locationCountry] = postage.location.split("-");
        var [destinationCountry] = data.deliveryLocation.split("-");

        if (locationCountry == "" || destinationCountry == "") return;

        if (locationCountry == destinationCountry) {
            if (postage.domestic?.available?.[destinationCountry]) {
                var priceRaw = postage.domestic?.available?.[destinationCountry];
                var priceString = (parseFloat(priceRaw) == 0) ? "FREE" : parseFloat(priceRaw).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 });

                return priceString;
            };
        } else {
            if (postage.international?.available?.[destinationCountry]) {
                var priceRaw = postage.international?.available?.[destinationCountry];
                var priceString = (parseFloat(priceRaw) == 0) ? "FREE" : parseFloat(priceRaw).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 });

                return priceString;
            } else {
                return "unavailable"
            };
        };
    };

    function handleRating(rating) {
        var classObject = {};
        var remainder = rating % 1;

        for (var i = 0; i < Math.floor(rating); i++) {
            classObject[i] = "fas fa-star";
        };

        if (remainder > 0 && remainder < 0.3) {
            classObject[Math.floor(rating)] = "far fa-star";
        } else if (remainder >= 0.3 && remainder < 0.8) {
            classObject[Math.floor(rating)] = "fas fa-star-half-alt";
        } else if (remainder >= 0.8) {
            classObject[Math.floor(rating)] = "fas fa-star";
        };

        for (var i = 0; i < 5; i++) {
            if (!classObject[i]) classObject[i] = "far fa-star";
        };

        return (
            <div>
                <i className={ classObject[0] }></i>
                <i className={ classObject[1] }></i>
                <i className={ classObject[2] }></i>
                <i className={ classObject[3] }></i>
                <i className={ classObject[4] }></i>
            </div>
        );
    };

    function handleChange(event) {
        const name = event.target.name;
        var value = event.target.value;

        if (inputs[name] == value) {
            $(event.target).prop("checked", false);

            value = "";
        };

        if ($(event.target).attr("type") == "checkbox") {
            var tmpArray = [];

            $("[name='" + $(event.target).attr("name") + "']:checked").each((i, e) => {
                tmpArray.push(e.value);
            });

            setInputs(values => (
                { ...values, [name]: tmpArray }
            ));
        } else {
            setInputs(values => (
                { ...values, [name]: value }
            ));
        };

        if (name === "pageselectinput") {
            if (value === '' || (Number(value) >= 1 && Number(value) <= Math.ceil(data.listings.length / inputs.show))) {
                setInputs(values => ({
                    ...values,
                    proposedPage: value
                }))
            }
        }
    };

    function handlePriceChange(e) {
        const { name, value } = e.target;
        const newValue = Number(value);

        setInputs(values => {
            const newFilterPrice = { ...values.filterPrice, [name]: newValue };

            if (name === "min" && newValue > values.filterPrice.max) {
                newFilterPrice.max = newValue;
            } else if (name === "max" && newValue < values.filterPrice.min) {
                newFilterPrice.min = newValue;
            }

            return {
                ...values,
                filterPrice: newFilterPrice
            };
        });
    }

    function handlePage(index) {
        setInputs(values => ({
            ...values,
            selectedPage: index,
            proposedPage: index + 1
        }));

        setData(values => (
            { ...values, showListings: data.listings.slice(index * inputs.show, (index + 1) * inputs.show) }
        ));

        $("body, html").animate({ scrollTop: $("ul.listings").offset().top - 64 }, 0);
    };

    // Handles when user clicks off the page input value then it triggers update
    const handleInputBlur = () => {
        const page = Number(inputs.proposedPage - 1);
        if (!isNaN(page) && page >= 0 && page < Math.ceil(data.listings.length / inputs.show)) {
            handlePage(page);
        } else {
            setInputs(values => ({
                ...values,
                selectedPage: page
            }))
        }
    };

    function resetFilters() {
        setInputs(values => ({
            ...values,
            filterPrice: { min: 0, max: inputs.filterPriceMaximum },
            filterCondition: [],
            filterStock: "",
            filterCurrencies: [],
            filterSellerKYC: "",
            filterBuyerKYC: "",
            filterShips: ""
        }));

        $(".sidebar-wrapper input").prop("checked", false);
        $(".filters-modal input").prop("checked", false);
    };

    function toggleMobileFilters() {
        setInputs(values => ({
            ...values,
            showFilters: !inputs.showFilters
        }));
    };

    function handleFilterOverlay(event) {
        const target = event.target;

        var results = $(".filters-modal").find(target).length;

        if (results > 0) return;

        setInputs(values => ({
            ...values,
            showFilters: !inputs.showFilters
        }));
    };

    const breadcrumbs = [
        { label: "Home", path: "/" },
        { label: "Search", path: "/" }
    ]

    return (
        <section className="category">
            <div className="container">
                <div className="breadcrumb-wrapper">
                    <div className="breadcrumb">
                        <Breadcrumb crumbs={breadcrumbs} />
                    </div>
                </div>

                <div className="category-wrapper">
                    <div className="content-wrapper">
                        <div className="sidebar-wrapper n600">
                            <div className="sidebar-title">
                                <div className="underline">Filters</div> 
                                <div className="reset click" onClick={ resetFilters }><i className="fal fa-redo"></i> Reset Filters</div>
                            </div>

                            <div className="filter">
                                <div className="heading click">Price <i className="fal fa-minus"></i></div>
                                <div className="price-filter-wrapper">
                                    <div className="price-sliders">
                                        <input
                                            type="range"
                                            name="min"
                                            min="0"
                                            max={inputs.filterPriceMaximum}
                                            value={inputs.filterPrice.min}
                                            onChange={handlePriceChange}
                                            className="slider"
                                        />

                                        <input
                                            type="range"
                                            name="max"
                                            min="0"
                                            max={inputs.filterPriceMaximum}
                                            value={inputs.filterPrice.max}
                                            onChange={handlePriceChange}
                                            className="slider"
                                        />
                                    </div>

                                    <div className="price-values">
                                        <input
                                            type="text"
                                            name="min"
                                            inputMode="numeric"
                                            pattern="[0-9]+"
                                            value={inputs.filterPrice.min}
                                            onChange={handlePriceChange}
                                        />
                                        <input
                                            type="text"
                                            name="max"
                                            pattern="[0-9]+"
                                            value={inputs.filterPrice.max}
                                            onChange={handlePriceChange}
                                        />

                                    </div>
                                </div>
                            </div>

                            <div className="filter">
                                <div className="heading click">Condition <i className="fal fa-minus"></i></div>
                                {
                                    filterConditions.map((condition, index) => (
                                        <>
                                            <input key={ index } type="checkbox" name="filterCondition" id={ condition } value={ condition } onClick={ handleChange }/>
                                            <label htmlFor={ condition }>{ condition }</label>
                                        </>
                                    ))
                                }
                            </div>

                            <div className="filter">
                                <div className="heading click">Stock <i className="fal fa-minus"></i></div>
                                {
                                    filterStock.map((stock, index) => (
                                        <>
                                            <input key={ index } type="radio" name="filterStock" id={ stock } value={ stock } onClick={ handleChange }/>
                                            <label htmlFor={ stock }>{ stock }</label>
                                        </>
                                    ))
                                }
                            </div>

                            <div className="filter">
                                <div className="heading click">Currencies <i className="fal fa-minus"></i></div>
                                {
                                    filterCurrencies.map((currency, index) => (
                                        <>
                                            <input key={ index } type="checkbox" name="filterCurrencies" id={ currency } value={ currency } onClick={ handleChange }/>
                                            <label htmlFor={ currency }>{ currency }</label>
                                        </>
                                    ))
                                }
                            </div>

                            <div className="filter">
                                <div className="heading click">Ships to { countryCodesDictionary[data.deliveryLocation] } <i className="fal fa-minus"></i></div>
                                {
                                    filterShips.map((ships, index) => (
                                        <>
                                            <input key={ index } type="radio" name="filterShips" id={ "filterShips" + ships } value={ ships } onClick={ handleChange }/>
                                            <label htmlFor={ "filterShips" + ships }>{ ships }</label>
                                        </>
                                    ))
                                }
                            </div>

                            <div className="filter">
                                <div className="heading click">Seller KYC Verified <i className="fal fa-minus"></i></div>
                                {
                                    filterSellerKYC.map((kyc, index) => (
                                        <>
                                            <input key={ index } type="radio" name="filterSellerKYC" id={ "filterSellerKYC" + kyc } value={ kyc } onClick={ handleChange }/>
                                            <label htmlFor={ "filterSellerKYC" + kyc }>{ kyc }</label>
                                        </>
                                    ))
                                }
                            </div>

                            <div className="filter">
                                <div className="heading click">Purchase Requires KYC <i className="fal fa-minus"></i></div>
                                {
                                    filterBuyerKYC.map((kyc, index) => (
                                        <>
                                            <input key={ index } type="radio" name="filterBuyerKYC" id={ "filterBuyerKYC" + kyc } value={ kyc } onClick={ handleChange }/>
                                            <label htmlFor={ "filterBuyerKYC" + kyc }>{ kyc }</label>
                                        </>
                                    ))
                                }
                            </div>
                        </div>

                        <div className="divider n600"></div>

                        <div className="listings-wrapper">
                            <div className="preferences">
                                <div className="sort">
                                <p className="n420">Sort By</p>
                                    <select name="sortBy" value={inputs.sortBy} onChange={handleChange} aria-label="sort by options">
                                        <option value="recommended">Recommended</option>
                                        <option value="lowtohigh">Price (Low-High)</option>
                                        <option value="hightolow">Price (High-Low)</option>
                                        <option value="mostsales">Best Selling</option>
                                    </select> 
                                <i className="fal fa-chevron-down b420"></i>
                                </div>

                                <div className="results n420">{data.listings.length} search results</div>

                                <div className="show">
                                <p className="n420">Show</p>
                                    <select name="show" value={inputs.show} onChange={handleChange} aria-label="show listings per page">
                                    <option value="10">10 listings</option>
                                    <option value="25">25 listings</option>
                                    <option value="50">50 listings</option>
                                </select>
                                <i className="fal fa-chevron-down b420"></i>
                                </div>
                            </div>

                            <button className="mobile-filters b420" onClick={ toggleMobileFilters }>Show Filters <i className="fal fa-filter"></i></button>

                            <div className={ inputs.showFilters ? "filters-modal active" : "filters-modal" } onClick={ handleFilterOverlay }>
                                <div className="filter-overlay">
                                    <div className="filter-header">
                                        <span>Filters</span>
                                        <button onClick={ toggleMobileFilters }>{ textStrings["header_cart_close"][state.language] } <i className="fal fa-long-arrow-right"></i></button>
                                    </div>

                                    <div className="filters">
                                        <button className="reset click" onClick={ resetFilters }><i className="fal fa-redo"></i> Reset Filters</button>

                                        <div className="filter">
                                            <div className="heading click">Price <i className="fal fa-minus"></i></div>
                                            <div className="price-filter-wrapper">
                                                <div className="price-sliders">
                                                    <input
                                                        type="range"
                                                        name="min"
                                                        min="0"
                                                        max={inputs.filterPriceMaximum}
                                                        value={inputs.filterPrice.min}
                                                        onChange={handlePriceChange}
                                                        className="slider"
                                                    />

                                                    <input
                                                        type="range"
                                                        name="max"
                                                        min="0"
                                                        max={inputs.filterPriceMaximum}
                                                        value={inputs.filterPrice.max}
                                                        onChange={handlePriceChange}
                                                        className="slider"
                                                    />
                                                </div>

                                                <div className="price-values">
                                                    <input
                                                        type="text"
                                                        name="min"
                                                        inputMode="numeric"
                                                        pattern="[0-9]+"
                                                        value={inputs.filterPrice.min}
                                                        onChange={handlePriceChange}
                                                    />
                                                    <input
                                                        type="text"
                                                        name="max"
                                                        pattern="[0-9]+"
                                                        value={inputs.filterPrice.max}
                                                        onChange={handlePriceChange}
                                                    />

                                                </div>
                                            </div>
                                        </div>

                                        <div className="filter">
                                            <div className="heading click">Condition <i className="fal fa-minus"></i></div>
                                            {
                                                filterConditions.map((condition, index) => (
                                                    <>
                                                        <input key={ index } type="checkbox" name="filterCondition" id={ condition + 1 } value={ condition } onClick={ handleChange }/>
                                                        <label htmlFor={ condition + 1 }>{ condition }</label>
                                                    </>
                                                ))
                                            }
                                        </div>

                                        <div className="filter">
                                            <div className="heading click">Stock <i className="fal fa-minus"></i></div>
                                            {
                                                filterStock.map((stock, index) => (
                                                    <>
                                                        <input key={ index } type="radio" name="filterStock" id={ stock + 1 } value={ stock } onClick={ handleChange }/>
                                                        <label htmlFor={ stock + 1 }>{ stock }</label>
                                                    </>
                                                ))
                                            }
                                        </div>

                                        <div className="filter">
                                            <div className="heading click">Currencies <i className="fal fa-minus"></i></div>
                                            {
                                                filterCurrencies.map((currency, index) => (
                                                    <>
                                                        <input key={ index } type="checkbox" name="filterCurrencies" id={ currency + 1 } value={ currency } onClick={ handleChange }/>
                                                        <label htmlFor={ currency + 1 }>{ currency }</label>
                                                    </>
                                                ))
                                            }
                                        </div>

                                        <div className="filter">
                                            <div className="heading click">Ships to { countryCodesDictionary[data.deliveryLocation] } <i className="fal fa-minus"></i></div>
                                            {
                                                filterShips.map((ships, index) => (
                                                    <>
                                                        <input key={ index } type="radio" name="filterShips" id={ "filterShips" + ships + 1 } value={ ships } onClick={ handleChange }/>
                                                        <label htmlFor={ "filterShips" + ships + 1 }>{ ships }</label>
                                                    </>
                                                ))
                                            }
                                        </div>

                                        <div className="filter">
                                            <div className="heading click">Seller KYC Verified <i className="fal fa-minus"></i></div>
                                            {
                                                filterSellerKYC.map((kyc, index) => (
                                                    <>
                                                        <input key={ index } type="radio" name="filterSellerKYC" id={ "filterSellerKYC" + kyc + 1 } value={ kyc } onClick={ handleChange }/>
                                                        <label htmlFor={ "filterSellerKYC" + kyc + 1 }>{ kyc }</label>
                                                    </>
                                                ))
                                            }
                                        </div>

                                        <div className="filter">
                                            <div className="heading click">Purchase Requires KYC <i className="fal fa-minus"></i></div>
                                            {
                                                filterBuyerKYC.map((kyc, index) => (
                                                    <>
                                                        <input key={ index } type="radio" name="filterBuyerKYC" id={ "filterBuyerKYC" + kyc + 1 } value={ kyc } onClick={ handleChange }/>
                                                        <label htmlFor={ "filterBuyerKYC" + kyc + 1 }>{ kyc }</label>
                                                    </>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ul className="listings">
                                {
                                    (data.showListings.length < 1 && data.listingsEmpty === null) && (
                                        <div className="loading">
                                            <i className="fad fa-spinner-third"></i>

                                            <span>Fetching listings</span>
                                        </div>
                                    )
                                }

                                {
                                    (data.showListings.length < 1 && data.listingsEmpty !== null) && (
                                        <div className="loading">
                                            <div className="banner">
                                                <i className="fal fa-inventory"></i>
                                                <div>No listings found</div>
                                                <div><Link to="/create-listing">Create a listing</Link> for free and be the first seller with these search terms</div>
                                            </div>
                                        </div>
                                    )                                    
                                }

                                {
                                    data.showListings?.map((listing, index) => (
                                        <li key={ index }>
                                            <Link to={ "/listing/" + listing._id }>
                                                <div className="thumbnail-wrapper">
                                                    <img src={ listing.thumbnail } alt={ listing.title }/>
                                                </div>
                                            </Link>

                                            <div className="info-wrapper">
                                                <div className="flex">
                                                    <Link to={ data.categories[listing.category]["url"] + data.categories[listing.category]["subcategories"][listing.subcategory]["url"] }>
                                                        <div className="subcategory">{ textStrings[listing.subcategory.replace("-", "_")][state.language] }</div>
                                                    </Link>

                                                    {
                                                        (state.cart.filter(item => item._id == listing._id).length > 0) && (
                                                            <div className="cart-status"><i className="fal fa-shopping-cart"></i> In Cart</div>
                                                        )
                                                    }
                                                </div>

                                                <Link to={ "/listing/" + listing._id }>
                                                    <div className="title">{ listing.title }</div>
                                                </Link>

                                                <div className="condition">{ listing.condition }</div>

                                                <div className="reviews" title={ listing.reviews?.rating }>{ handleRating(listing.reviews?.rating || 0) } <span>{ "(" + (listing.reviews?.count || 0) + " ratings)" }</span></div>

                                                <div className="price">
                                                    { parseFloat(listing.price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }
                                                    
                                                    {
                                                        (handleShippingCost(listing.postage) == "unavailable") && (
                                                            <div className="shipping-cost">+ <span className="unavailable">shipping not available to { countryCodesDictionary[data.deliveryLocation] }</span></div>
                                                        )
                                                    }

                                                    {
                                                        (handleShippingCost(listing.postage) != "unavailable") && (
                                                            <div className="shipping-cost">+ <span className={ handleShippingCost(listing.postage)?.toLowerCase() }>{ handleShippingCost(listing.postage) }</span> shipping</div>
                                                        )
                                                    }
                                                </div>

                                                {
                                                    (listing.options.length > 0) && (
                                                        <div className="options">Customization Options</div>
                                                    )
                                                }

                                                <div className="stock">
                                                    { listing.variations.filter(v => v.stock > 0).length > 0 ? <div className="in-stock"><i className="fas fa-check"></i><span>In Stock</span></div> : <div className="out-stock"><i className="fas fa-times"></i><span>Out of Stock</span></div> }
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                }

                                {
                                    (data.listings.length > inputs.show) && (
                                        <div className="pages">
                                            <button disabled={inputs.selectedPage < 1} onClick={() => handlePage(0)} aria-label="navigate to first listings page"><i className="fas fa-fast-backward" /></button>
                                            <button disabled={inputs.selectedPage < 1} onClick={() => handlePage(inputs.selectedPage - 1)} aria-label="navigate to previous listings page"><i className="fas fa-backward" /></button>

                                            <div onClick={handlePageFocus}>
                                                <input type="text" inputMode="numeric" name="pageselectinput" ref={inputRef} pattern="[0-9]+" min="1" max={Math.ceil(data.listings.length / inputs.show)} value={inputs.proposedPage} onChange={handleChange} onBlur={handleInputBlur} onKeyUp={handleKeypress}/>
                                                /
                                                <span>{Math.ceil(data.listings.length / inputs.show)}</span>
                                            </div>

                                            <button disabled={inputs.selectedPage >= Math.ceil(data.listings.length / inputs.show) - 1} onClick={() => handlePage(inputs.selectedPage + 1)} aria-label="navigate to next listings page"><i className="fas fa-forward" /></button>
                                            <button disabled={inputs.selectedPage >= Math.ceil(data.listings.length / inputs.show) - 1} onClick={() => handlePage(Math.ceil(data.listings.length / inputs.show) - 1)} aria-label="navigate to last listings page"><i className="fas fa-fast-forward" /></button>
                                        </div>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Search;
