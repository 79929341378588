import { React, useState, useEffect, useMemo, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { defaultVouchers } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Proton from "assets/javascript/proton";

import Breadcrumb from "components/breadcrumb";
import Sidebar from "components/account/sidebar";

function Vouchers() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({});
    const [data, setData] = useState({});

    useEffect(() => {
        const url = window.location.href;
        const params = new URL(url).searchParams;
        const code = params.get("code");

        if (code) {
            dispatch({
                type: "setModal",
                value: {
                    action: "redeem",
                    data: code
                }
            });
    
            $("div.input-modal").addClass("active").find("section.redeem-voucher").addClass("active");
        };

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) {
            dispatch({
                type: "promptLogin",
                value: "/account/vouchers"
            });
            
            return navigate("/");
        };

        //if (state.authenticated) return handleDataFetch();
    }, [state.authenticated]);

    function handleRedeemModal() {
        dispatch({
            type: "setModal",
            value: {
                action: "redeem",
                data: ""
            }
        });

        $("div.input-modal").addClass("active").find("section.redeem-voucher").addClass("active");
    };

    function handleBuy(event, voucherId) {
        event.preventDefault();

        dispatch({
            type: "setModal",
            value: {
                action: "voucher",
                data: voucherId
            }
        });

        $("div.input-modal").addClass("active").find("section.buy-voucher").addClass("active");
    };

    const breadcrumbs = [
        { label: "Home", path: "/" },
        { label: textStrings["my_account"][state.language], path: "/account" },
        { label: textStrings["account_vouchers"][state.language], path: "/account/vouchers" }
    ]

    return (
        <section className="account">
            <div className="page-header">My Account</div>

            <div className="container">
                <div className="breadcrumb-wrapper border-bottom">
                    <div className="breadcrumb">
                        <Breadcrumb crumbs={breadcrumbs} />
                    </div>
                </div>

                <div className="account-wrapper">
                    <Sidebar />

                    <div className="content-wrapper">
                        <section className="vouchers">
                            <div className="content-title-area">
                                <div className="content-title"><i className="fal fa-gift-card"></i> { textStrings["account_gift_vouchers"]?.[state.language] } <span onClick={ handleRedeemModal }><a>Redeem voucher</a></span></div>
                            </div>

                            <div className="buy-voucher">
                                {
                                    Object.keys(defaultVouchers)?.map((i) => (
                                        <div className="voucher" style={{ "border-color": defaultVouchers[i]?.color }}>
                                            <img src="/assets/images/storex_logo_grey.svg" alt="" className="watermark" />
                                            <div className="denomination" style={{ "color": defaultVouchers[i]?.color }}><span>$</span>{ defaultVouchers[i]?.price }</div>
                                            <div className="ticker">{ defaultVouchers[i]?.ticker }</div>
                                            <button className="buy" style={{ "background-color": defaultVouchers[i]?.color, "color": "white" }} onClick={ (e) => handleBuy(e, i) }>{ textStrings["buy_now"]?.[state.language] }</button>
                                            <img src={ defaultVouchers[i]?.logo } alt="" className="logo" />
                                        </div>
                                    ))
                                }
                            </div>
                        </section>

                        <section className="promotions">
                            <div className="content-title-area">
                                <div className="content-title"><i className="fal fa-gift"></i> { textStrings["account_promotions"]?.[state.language] }</div>
                            </div>

                            <ul>
                                <div key={ 1 } className="empty">You have no active promotions</div>
                                <span key={ 2 } className="empty"><Link to="/search">Browse listings</Link> to discover deals</span>
                            </ul>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Vouchers;
