import $ from "jquery";
import Cookies from "js-cookie";
import { apiEndpoint } from "constants";

export function fetchSellerAccount(sellerId) {
    if (!sellerId) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "GET",
        url: apiEndpoint + "/account/seller",
        data: {
            sellerId: sellerId
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");

            var { message } = res.responseJSON;
            
            if (message.includes("exist")) {
                window.location.href = "/not-found";
            };
        }
    });
};

export function fetchSellerListings(sellerId) {
    if (!sellerId) return Promise.reject("missing_parameters");

    return $.ajax({
        type: "GET",
        url: apiEndpoint + "/listing/seller",
        data: {
            sellerId: sellerId
        },
        error: function(res) {
            if (res.status === 0) return $("div.modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");

            var { message } = res.responseJSON;

            $("div.modal").addClass("error").find("div.text").html(message);
        }
    });
};