import { Link } from "react-router-dom"

export default function TrendingCategories(props) {
    return (
        <Link to={props.link} className={`click${props?.extraClasses ? ` ${props.extraClasses}` : ''}`}>
            <div className="category">
                <img src={props.image} alt={props.title + " image"} />
                <span>{props.title}</span>
            </div>
        </Link>
    )
}