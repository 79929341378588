import { React, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

import { useStateValue } from "state";
import { regionCodesDictionary, countryCodesDictionary } from "constants";
import { textStrings } from "localization";

import Breadcrumb from "components/breadcrumb"

import shippingZonesImg from "../assets/images/shipping_zones.webp"

function ShippingZones() {
    const [state, dispatch] = useStateValue();

    const [regions, setRegions] = useState([
        { zone: "USA", color: "#c5c6c6", name: "United States of America", active: false },
        { zone: "UK", color: "#555756", name: "United Kingdom", active: false },
        { zone: "EUR", color: "#0095d1", name: "Europe", active: false },
        { zone: "WZ1", color: "#d40c18", name: "World Zone 1", active: false },
        { zone: "WZ2", color: "#867c77", name: "World Zone 2", active: false }
    ])

    function handleToggle(zone) {
        setRegions(regions.map(region =>
            region.zone === zone ? { ...region, active: !region.active } : region
        ))
    };

    const breadcrumbs = [
        { label: "Home", path: "/" },
        { label: textStrings["shipping_zones"][state.language], path: "/shipping-zones" }
    ]

    return (
        <section className="shipping-zones">
            <div className="page-header">Shipping Zones</div>

            <div className="container">
                <div className="breadcrumb-wrapper">
                    <div className="breadcrumb">
                        <Breadcrumb crumbs={breadcrumbs} />
                    </div>
                </div>

                <img src={shippingZonesImg} alt="Shipping Zones" />

                <ul>
                    {
                        regions.map((region, index) => (
                            <li key={index} className={"accordion" + (region.active ? " active" : "")} >
                                <button style={{ borderLeft: `5px solid ${region.color}` }} onClick={() => handleToggle(region.zone)}>{region.name}</button>
                                <div className="panel">
                                    <p>
                                        {
                                            regionCodesDictionary[region.zone].sort().map((cc, index) => (
                                                countryCodesDictionary[cc] + (index == regionCodesDictionary[region.zone].length - 1 ? "" : ", ")
                                            ))
                                        }
                                    </p>
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </section>
    );
};

export default ShippingZones;
