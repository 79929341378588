import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { countryCodes, countryCodesDictionary, regionDictionary, dummyCategories, dummyWeeklyDeal, dummyListings } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Cart from "assets/javascript/cart";
import * as Proton from "assets/javascript/proton";

import Breadcrumb from "components/breadcrumb"

function OrderConfirmation() {
    const navigate = useNavigate();
    const [slidePosition, setPosition] = useState(0);
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({ prices: {}, payment: "", listing: { scrollTop: 0, scrollBottom: 3, variation: -1, option0: [], option1: [], option2: [], buttonClass: "", isCarted: false, isWished: false, sellerKYC: false, deliveryLocation: "", deliveryCost: "", locationsString: "", locationsMore: false, locationsOverflow: false } });
    const [data, setData] = useState({ categories: dummyCategories, listing: dummyWeeklyDeal, dummyListings: dummyListings });

    useEffect(() => {
        if (!state.order?.txId || !state.order?.orderId) return navigate("/");

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) {
            dispatch({
                type: "promptLogin",
                value: "/order-confirmation"
            });
            
            return navigate("/", { replace: true });
        };
    }, [state.authenticated]);

    const breadcrumbs = [
        { label: "Home", path: "/" },
        { label: textStrings["order_confirmation"][state.language], path: "/order-confirmation" }
    ]

    return (
        <section className="order-confirmation">
            <div className="page-header">Order Confirmed</div>

            <div className="container">
                <div className="breadcrumb-wrapper">
                    <div className="breadcrumb">
                        <Breadcrumb crumbs={breadcrumbs} />
                    </div>
                </div>

                <i className="fal fa-check-circle"></i>

                <div className="thank">Thank you for your order</div>

                <div className="orderNo">Order Number #{ state.order?.orderId }</div>

                <div className="tx">Transaction <a href={ "https://explorer.xprnetwork.org/transaction/" + state.order?.txId } target="_blank">{ state.order?.txId }</a></div>

                <Link to={ "/account/orders" }><button>View Order</button></Link>
            </div>
        </section>
    );
};

export default OrderConfirmation;
