import { React, createContext, useContext, useReducer } from "react";

export const StateContext = createContext();

export const StateProvider = ({ state, reducer, children }) => (
    <StateContext.Provider value={ useReducer(reducer, state) }>
        { children }
    </StateContext.Provider>
);

export const useStateValue = () => useContext(StateContext);
