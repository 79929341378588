import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { defaultAccount, countryCodes, countryCodesDictionary, regionDictionary } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Proton from "assets/javascript/proton";

import Breadcrumb from "components/breadcrumb"
import Sidebar from "components/account/sidebar";
import Address from "components/account/address"
import RegisteredAddress from "components/account/registeredAddress";

function Addresses() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({  });
    const [data, setData] = useState({  });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) {
            dispatch({
                type: "promptLogin",
                value: "/account/addresses"
            });
            
            return navigate("/");
        };
    }, [state.authenticated]);

    useEffect(() => {
        if (state.account.id) {
            setData(values => (
                { ...values, regAddress: state.account.registeredAddress, billAddress: state.account.billingAddress, delAddress: state.account.deliveryAddress }
            ));
    
            setInputs(values => ({
                ...values,
                regAddress: state.account.registeredAddress,
                billAddress: [],
                delAddress: []
            }));
        };
    }, [state.account]);

    function handleChange(event) {
        const [addressType, name] = event.target.name.split("-");
        var value = event.target.value;

        if (name == "country") {
            if (["US", "UM"].includes(value)) {
                $("label[for='regAddress-state']").html("State");
                $("label[for='regAddress-zipcode']").html("Zipcode");
            } else {
                $("label[for='regAddress-state']").html("County");
                $("label[for='regAddress-zipcode']").html("Postcode");
            };
        };

        setInputs(values => ({
            ...values,
            [addressType]: {
                ...values[addressType],
                [name]: value
            }
        }));
    };

    function handleRegisteredAddress(event) {
        event.preventDefault();

        $("button.reg-address").html("<i class='fad fa-spinner-third'></i> Saving address");

        var showSuccess = () => {
            $("div.error-modal").addClass("success").find("div.text").html("Your registered address has been updated");

            $("button.reg-address").html(textStrings["save_changes"][state.language]);
        };

        var showError = (message) => {
            $("div.error-modal").addClass("error").find("div.text").html("There was an error with your request: " + message);
        };

        var showAuthError = () => {
            $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

            navigate("/");
        };

        Account.updateRegisteredAddress(state.session.auth.actor, inputs).then(res => {
            showSuccess();
        }).catch(err => {
            var { message } = err.responseJSON || { message: err.responseText };

            if (message?.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.updateRegisteredAddress(state.session.auth.actor, inputs).then(res => {
                        showSuccess();
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err.responseText };

                        showError(message);

                        $("button.reg-address").html(textStrings["save_changes"][state.language]);
                    });
                }).catch(err => {
                    showAuthError();
                });
            } else {
                showError(message);

                $("button.reg-address").html(textStrings["save_changes"][state.language]);
            };
        });
    };

    function handleNewAddress(addressType) {
        $("div.input-modal").addClass("active").find("section.new-address").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "add",
                type: addressType
            }
        });
    };

    function handleEditAddress(target, addressType, address) {
        $("div.input-modal").addClass("active").find("section.edit-address").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "edit",
                type: addressType,
                address: address
            }
        });
    };

    function handleDeleteAddress(target, addressType, addressId) {
        $(target).html("<i class='fad fa-spinner-third'></i> Deleting address");

        var showSuccess = (res) => {
            $("div.error-modal").addClass("success").find("div.text").html("Your address has been deleted");

            dispatch({
                type: "setAccount",
                value: {
                    account: {
                        id: res._id || defaultAccount.id,
                        fullName: res.fullName || defaultAccount.fullName,
                        email: res.email || defaultAccount.email,
                        phone: res.phone || defaultAccount.phone,
                        country: res.country || defaultAccount.country,
                        referral: res.referral || defaultAccount.referral,
                        registeredAddress: res.registeredAddress || defaultAccount.registeredAddress,
                        billingAddress: res.billingAddress || defaultAccount.billingAddress,
                        deliveryAddress: res.deliveryAddress || defaultAccount.deliveryAddress
                    }
                }
            });

            $(target).html(textStrings["delete"][state.language]);
        };

        var showError = (message) => {
            $("div.error-modal").addClass("error").find("div.text").html("There was an error with your request: " + message);

            $(target).html(textStrings["delete"][state.language]);
        };

        var showAuthError = () => {
            $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

            navigate("/");
        };

        Account.deleteAddress(state.session.auth.actor, inputs, addressType, addressId).then(res => {
            showSuccess(res);
        }).catch(err => {
            var { message } = err.responseJSON || { message: err.responseText };

            if (message?.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.deleteAddress(state.session.auth.actor, inputs, addressType, addressId).then(res => {
                        showSuccess(res);
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err.responseText };

                        showError(message);
                    });
                }).catch(err => {
                    showAuthError();
                });
            } else {
                showError(message);
            };
        });
    };

    const breadcrumbs = [
        { label: "Home", path: "/" },
        { label: textStrings["my_account"][state.language], path: "/account" },
        { label: textStrings["account_addresses"][state.language], path: "/account/addresses" }
    ];
  
    const addressTypes = [
        { title: "Billing Address", data: data.billAddress, type: "billing" },
        { title: "Delivery Address", data: data.delAddress, type: "delivery" }
    ];

    return (
        <section className="account">
            <div className="page-header">My Account</div>

            <div className="container">
                <div className="breadcrumb-wrapper border-bottom">
                    <div className="breadcrumb">
                        <Breadcrumb crumbs={breadcrumbs} />
                    </div>
                </div>

                <div className="account-wrapper">
                    <Sidebar />

                    <div className="content-wrapper">
                        <section className="reg-address">
                            <div className="content-title-area">
                                <div className="content-title"><i className="fal fa-map-marker-alt"></i> { textStrings["account_reg_address"][state.language] }</div>
                            </div>

                            <RegisteredAddress handleRegisteredAddress={handleRegisteredAddress} handleChange={handleChange} inputs={inputs} state={state} />
                        </section>

                        {
                            addressTypes.map((item, index) => (
                                <section key={index} className="address-cards">
                                    <div className="content-title-area">
                                        <div className="content-title"><i className="fal fa-map-marker-alt" /> {item.title}</div>
                                    </div>

                                    <Address type={item.type} addresses={item.data} handleEditAddress={handleEditAddress} handleDeleteAddress={handleDeleteAddress} handleNewAddress={handleNewAddress} />
                                </section>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Addresses;
