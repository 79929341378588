import { Link } from "react-router-dom"

export default function BlogPost(props) {
    return (
        <section className="blog-post">
            <a href={props.link} target="_blank" rel="noreferrer">
                <div className="thumbnail-wrapper">
                    <img src="https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80" alt={props.title} />
                    <div className="date">
                        <div className="month">{props.published.month}</div>
                        <div className="day">{props.published.day}</div>
                    </div>
                </div>
            </a>

            <div className="info-wrapper">
                <a href={props.link} target="_blank" rel="noreferrer">{props.title}</a>
                <div className="author">by <Link to={props.authorLink}>{props.author}</Link></div>
            </div>
        </section>
    )
}