import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { notificationTemplates } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Cart from "assets/javascript/cart";
import * as Proton from "assets/javascript/proton";

import Breadcrumb from "components/breadcrumb";

function Notifications() {
    const navigate = useNavigate();
    const [slidePosition, setPosition] = useState(0);
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({  });
    const [data, setData] = useState({  });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) {
            dispatch({
                type: "promptLogin",
                value: "/notifications"
            });
            
            return navigate("/", { replace: true });
        };
    }, [state.authenticated]);

    useEffect(() => {
        if (state.account.id) {
            setData(values => (
                { ...values, notifications: state.account?.notifications }
            ));
        };
    }, [state.account]);

    function handleRead(notificationId) {
        var showSuccess = (res) => {
            dispatch({
                type: "setNotifications",
                value: res
            });

            setData(values => (
                { ...values, notifications: res }
            ));
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");
        };

        Account.notificationRead(state.session.auth.actor, notificationId).then(res => {
            showSuccess(res);
        }).catch(err => {
            var { message } = err.responseJSON || { message: err };

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.notificationRead(state.session.auth.actor, notificationId).then(res => {
                        showSuccess(res);
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err };

                        showError(message);
                    });
                }).catch(err => {
                    $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

                    navigate("/");
                });
            } else {
                showError(message);
            };
        });
    };

    function handleReadAll() {
        if (data.notifications?.length < 1) return;

        var showSuccess = (res) => {
            dispatch({
                type: "setNotifications",
                value: res
            });

            setData(values => (
                { ...values, notifications: res }
            ));
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");
        };

        Account.notificationReadAll(state.session.auth.actor).then(res => {
            showSuccess(res);
        }).catch(err => {
            var { message } = err.responseJSON || { message: err };

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.notificationReadAll(state.session.auth.actor).then(res => {
                        showSuccess(res);
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err };

                        showError(message);
                    });
                }).catch(err => {
                    $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

                    navigate("/");
                });
            } else {
                showError(message);
            };
        });
    };

    function handleDelete(notificationId) {
        var showSuccess = (res) => {
            dispatch({
                type: "setNotifications",
                value: res
            });

            setData(values => (
                { ...values, notifications: res }
            ));
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");
        };

        Account.notificationDelete(state.session.auth.actor, notificationId).then(res => {
            showSuccess(res);
        }).catch(err => {
            var { message } = err.responseJSON || { message: err };

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.notificationDelete(state.session.auth.actor, notificationId).then(res => {
                        showSuccess(res);
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err };

                        showError(message);
                    });
                }).catch(err => {
                    $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

                    navigate("/");
                });
            } else {
                showError(message);
            };
        });
    };

    function handleDeleteAll() {
        if (data.notifications?.length < 1) return;
        
        var showSuccess = (res) => {
            dispatch({
                type: "setNotifications",
                value: res
            });

            setData(values => (
                { ...values, notifications: res }
            ));
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");
        };

        Account.notificationDeleteAll(state.session.auth.actor).then(res => {
            showSuccess(res);
        }).catch(err => {
            var { message } = err.responseJSON || { message: err };

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.notificationDeleteAll(state.session.auth.actor).then(res => {
                        showSuccess(res);
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err };

                        showError(message);
                    });
                }).catch(err => {
                    $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

                    navigate("/");
                });
            } else {
                showError(message);
            };
        });
    };

    const breadcrumbs = [
        { label: 'Home', path: '/' },
        { label: textStrings["notifications"][state.language], path: "/notifications" }
    ]

    return (
        <section className="notifications">
            <div className="page-header">Notifications</div>

            <div className="container-small">
                <div className="breadcrumb-wrapper">
                    <div className="breadcrumb">
                        <Breadcrumb crumbs={breadcrumbs} />
                    </div>
                </div>

                <div className="actions">
                    <button className="read" onClick={ handleReadAll }><i className="fal fa-envelope-open"></i> Mark All Read</button>
                    <button className="clear" onClick={ handleDeleteAll }><i className="fal fa-trash"></i> Clear All</button>
                </div>

                <div className="notification-wrapper">
                    {
                        (data.notifications?.length < 1) && (
                            <div className="empty">
                                <i className="fal fa-bell-slash"></i>
                                <p>No new notifications</p>
                            </div>
                        )
                    }

                    {
                        (data.notifications?.length > 0) && (
                            data.notifications.toReversed().map(n => (
                                (notificationTemplates?.[n.type]) && (
                                    <div className="notification">
                                        <div className={ "icon " + notificationTemplates?.[n.type]?.color }>
                                            <i className={ notificationTemplates?.[n.type]?.icon }></i>
                                        </div>

                                        <div className="notif-content">
                                            <div className="text">
                                                <div className="title">{ notificationTemplates?.[n.type]?.title }</div>
                                                <div className="subtitle">{ notificationTemplates?.[n.type]?.text?.replaceAll("[username]", n.data?.username)?.replaceAll("[listingId]", n.data?.listingId)?.replaceAll("[orderId]", n.data?.orderId)?.replaceAll("[refundAmount]", n.data?.refundAmount)?.replaceAll("[suspendReason]", n.data?.suspendReason) }</div>
                                                <div className="date">{ new Date(n.createdAt).toLocaleDateString() }, { new Date(n.createdAt).toLocaleTimeString().slice(0, 5) }</div>
                                            </div>

                                            <div className="action">
                                                <Link to={ notificationTemplates?.[n.type]?.action.link?.replaceAll("[username]", n.data?.username) || "/" }><button>{ notificationTemplates?.[n.type]?.action.text }</button></Link>
                                                {
                                                    (!n.markedRead) && (
                                                        <i className="fal fa-envelope-open" onClick={ () => handleRead(n._id) }></i>
                                                    )
                                                }
                                                <i className="fal fa-trash" onClick={ () => handleDelete(n._id) }></i>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ))
                        )
                    }
                </div>
            </div>
        </section>
    );
};

export default Notifications;
