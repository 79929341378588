import { React, useState, useEffect, useMemo, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";
import * as CryptoJS from 'crypto-js';

import { useStateValue } from "state";
import { countryCodes, countryCodesDictionary, regionDictionary, dummyCategories, dummyListings, dummyWeeklyDeal } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Proton from "assets/javascript/proton";

import Breadcrumb from "components/breadcrumb"
import Sidebar from "components/account/sidebar";

function Orders() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({ selectedPage: 0, orderedListingsSearch: "" });
    const [data, setData] = useState({ categories: dummyCategories });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) {
            dispatch({
                type: "promptLogin",
                value: "/account/orders"
            });
            
            return navigate("/");
        };

        if (state.authenticated) return handleDataFetch();
    }, [state.authenticated]);

    function handleDataFetch() {
        var successOrders = (res) => {
            setData(values => (
                { ...values, rawOrders: res.reverse(), orders: res.slice(inputs.selectedPage * 10, (inputs.selectedPage * 10) + 10) }
            ));
        };

        var error = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error authenticating your request, please try again");

            $("section.create-listing button.submit").html(textStrings["submit_listing"][state.language]);
        };

        Account.fetchOrderListings(state.session.auth.actor).then(res => {
            successOrders(res);
        }).catch(err => {
            var { message } = err.responseJSON || { message: err };

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.fetchOrderListings(state.session.auth.actor).then(res => {
                        successOrders(res);
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err };

                        error(message);
                    });
                }).catch(err => {
                    $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

                    navigate("/");
                });
            } else {
                error(message);
            };
        });
    };

    function handleRating(rating) {
        var classObject = {};
        var remainder = (rating % 1).toFixed(1);

        for (var i = 0; i < Math.floor(rating); i++) {
            classObject[i] = "fas fa-star fill";
        };

        if (remainder > 0 && remainder < 0.3) {
            classObject[Math.floor(rating)] = "fas fa-star";
        } else if (remainder >= 0.3 && remainder < 0.8) {
            classObject[Math.floor(rating)] = "fad fa-star-half";
        } else if (remainder >= 0.8) {
            classObject[Math.floor(rating)] = "fas fa-star";
        };

        for (var i = 0; i < 5; i++) {
            if (!classObject[i]) classObject[i] = "fas fa-star";
        };

        return (
            <div>
                <i className={ classObject[0] }></i>
                <i className={ classObject[1] }></i>
                <i className={ classObject[2] }></i>
                <i className={ classObject[3] }></i>
                <i className={ classObject[4] }></i>
            </div>
        );
    };

    function handleOrderDetails(listing) {
        $("div.input-modal").addClass("active").find("section.order-details").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "order-details",
                listing: listing
            }
        });
    };

    function handleReview(listing, button) {
        $(button).html("<i class='fad fa-spinner-third'></i> Reviewing");

        $("div.input-modal").addClass("active").find("section.leave-review").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "leave-review",
                data: {
                    listing: listing,
                    button: button
                }
            }
        });
    };

    function handleReceived(orderId, button) {
        $(button).html("<i class='fad fa-spinner-third'></i> Completing");

        var showSuccess = () => {
            $("div.error-modal").addClass("success").find("div.text").html("Order has been successfully completed");

            $(button).html(textStrings["mark_received"][state.language]);

            handleDataFetch();
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");

            $(button).html(textStrings["mark_received"][state.language]);
        };

        Proton.completeOrder(state, orderId).then(tx => {
            if (!tx.processed) return showError("The transaction has not been broadcast to the blockchain, please try again");

            var txId = tx.processed?.id || "";

            Listing.completeOrder(state.session.auth.actor, orderId, txId).then(res => {
                showSuccess(res);
            }).catch(err => {
                var { message } = err.responseJSON || { message: err };
    
                if (message.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Listing.completeOrder(state.session.auth.actor, orderId, txId).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            var { message } = err.responseJSON || { message: err };
    
                            showError(message);
                        });
                    }).catch(err => {
                        $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");
    
                        navigate("/");
                    });
                } else {
                    showError(message);
                };
            });
        }).catch(err => {
            showError(err.error?.details?.[0]?.message || err);
        });
    };

    function handleExtend(orderId, button) {
        $(button).html("<i class='fad fa-spinner-third'></i> Extending");

        var showSuccess = () => {
            $("div.error-modal").addClass("success").find("div.text").html("Order escrow has been successfully extended");

            $(button).html(textStrings["extend_escrow"][state.language]);

            handleDataFetch();
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");

            $(button).html(textStrings["extend_escrow"][state.language]);
        };

        Proton.extendEscrow(state, orderId).then(tx => {
            if (!tx.processed) return showError("The transaction has not been broadcast to the blockchain, please try again");

            var txId = tx.processed?.id || "";

            Listing.extendEscrow(state.session.auth.actor, orderId, txId).then(res => {
                showSuccess(res);
            }).catch(err => {
                var { message } = err.responseJSON || { message: err };
    
                if (message.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Listing.extendEscrow(state.session.auth.actor, orderId, txId).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            var { message } = err.responseJSON || { message: err };
    
                            showError(message);
                        });
                    }).catch(err => {
                        $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");
    
                        navigate("/");
                    });
                } else {
                    showError(message);
                };
            });
        }).catch(err => {
            showError(err.error?.details?.[0]?.message || err);
        });
    };

    function handleDispute(listing, button) {
        $(button).html("<i class='fad fa-spinner-third'></i> Disputing");
        
        $("div.input-modal").addClass("active").find("section.create-dispute").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "create-dispute",
                data: {
                    listing: listing,
                    button: button
                }
            }
        });
    };

    function handlePage(index) {
        setInputs(values => (
            { ...values, selectedPage: index }
        ));

        setData(values => (
            { ...values, orders: data?.rawOrders?.slice(index * 10, (index + 1) * 10) }
        ));

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };

    function handleChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        setInputs(values => ({
            ...values,
            [name]: value
        }));
    }

    const filteredOrderedListings = data?.orders?.filter((l) => {
        const searchWords = inputs.orderedListingsSearch.toLowerCase().split(' ');
        return searchWords.every(word => l.title.toLowerCase().includes(word));
    });

    const breadcrumbs = [
        { label: "Home", path: "/" },
        { label: textStrings["my_account"][state.language], path: "/account" },
        { label: textStrings["account_orders"][state.language], path: "/account/orders" }
    ]

    return (
        <section className="account">
            <div className="page-header">My Account</div>

            <div className="container">
                <div className="breadcrumb-wrapper border-bottom">
                    <div className="breadcrumb">
                        <Breadcrumb crumbs={breadcrumbs} />
                    </div>
                </div>

                <div className="account-wrapper">
                    <Sidebar />

                    <div className="content-wrapper">
                        <section className="order-history">
                        <div className="content-title-area">
                                <div className="content-title"><i className="fal fa-tags"></i> {textStrings["account_orders"][state.language]}</div>

                                <input type="text" name="activeListingsSearch" onChange={handleChange} value={inputs.activeListingsSearch || ""} autoComplete="off" placeholder="Search..." />
                            </div>

                            <div className="active-wrapper">
                                <ul>
                                    {
                                        (data.orders == null) && (
                                            <div className="loading">
                                                <i className="fad fa-spinner-third"></i>

                                                <span>Fetching orders</span>
                                            </div>
                                        )
                                    }

                                    {
                                        (data?.orders?.length < 1) && (
                                            <>
                                                <div key={ 1 } className="empty">You haven't purchased any listings in the past 30 days</div>
                                                <span key={ 2 } className="empty"><Link to="/search">Browse listings</Link> to discover deals</span>
                                            </>
                                        )
                                    }

                                    {
                                        filteredOrderedListings?.map((listing, index) => (
                                            <Fragment key={ index }>
                                                <div className="sale-overview">
                                                    <div className="stat">
                                                        <div className="subtitle">Order Number</div>
                                                        <div className="value">{ listing.orderNumber }</div>
                                                    </div>
                                                    <div className="stat">
                                                        <div className="subtitle">Order Date</div>
                                                        <div className="value">{ new Date(listing.createdAt).toLocaleDateString() }, { new Date(listing.createdAt).toLocaleTimeString().slice(0, 5) }</div>
                                                    </div>
                                                    <div className="stat n900">
                                                        <div className="subtitle">Transaction ID</div>
                                                        <div className="value"><a href={ "https://explorer.xprnetwork.org/transaction/" + listing.txId } target="_blank">{ listing.txId }</a></div>
                                                    </div>
                                                    <div className="stat">
                                                        <div className="subtitle">Seller</div>
                                                        <div className="value"><Link to={ "/profile/" + listing.sellerId }>{ listing.sellerId }</Link> <Link to={ "/messages/" + listing.sellerId }><i className="fal fa-envelope message"></i></Link></div>
                                                    </div>
                                                    <div className="stat">
                                                        <div className="subtitle">Order Total</div>
                                                        <div className="value">
                                                            { ((parseFloat(listing.purchasedVariation.price) * listing.purchasedQuantity) + parseFloat(listing.purchasedShipping || 0)).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }
                                                            {
                                                                (listing.price != listing.purchasedVariation.price) && (
                                                                    <i className="fal fa-redo" title={ "$" + (listing.price.split(" ")[0] - listing.purchasedVariation.price.split(" ")[0]).toFixed(2) + " has been refunded" }></i>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <li>
                                                    <div className="mobile-wrapper">
                                                        <Link to={ "/listing/" + CryptoJS.MD5(listing.listingId).toString() }>
                                                            <div className="thumbnail-wrapper">
                                                                <img src={ listing.thumbnail } alt={ listing.title } />
                                                            </div>
                                                        </Link>

                                                        <div className="info">
                                                            <Link to={ data.categories[listing.category]["url"] + data.categories[listing.category]["subcategories"][listing.subcategory]["url"] }><div className="subcategory">{ textStrings[listing.subcategory.replace("-", "_")][state.language] }</div></Link>
                                                            <Link to={ "/listing/" + CryptoJS.MD5(listing.listingId).toString() }><div className="title">{ listing.title }</div></Link>
                                                            {/*<div className="reviews" title={ listing.reviews?.rating }>{ handleRating(listing.reviews?.rating || 0) } <span>{ "(" + (listing.reviews?.count || 0) + " ratings)" }</span></div>*/}
                                                            <div className="price">{ parseFloat(listing.price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) } <span>({ listing.purchasedCurrency })</span></div>
                                                            <div className="options">
                                                                {
                                                                    (listing.purchasedVariation.option0) && (
                                                                        <span>{ listing.purchasedVariation.option0 }</span>
                                                                    )
                                                                }
                                                                {
                                                                    (listing.purchasedVariation.option1) && (
                                                                        <span> / { listing.purchasedVariation.option1 }</span>
                                                                    )
                                                                }
                                                                {
                                                                    (listing.purchasedVariation.option2) && (
                                                                        <span> / { listing.purchasedVariation.option2 }</span>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className="status-icons">
                                                                <div className="icon">
                                                                    <span>Paid</span>
                                                                    <i className="fas fa-money-bill-wave active"></i>
                                                                </div>

                                                                <div className="icon">
                                                                    <span>Shipped</span>
                                                                    <i className={ "fas fa-truck-loading" + (listing.dispatched ? " active" : "") }></i>
                                                                </div>

                                                                {
                                                                    (listing.disputed) && (
                                                                        <div className="icon">
                                                                            <span>Disputed</span>
                                                                            <i className="fas fa-gavel alert"></i>
                                                                        </div>
                                                                    )
                                                                }
                                                                
                                                                {
                                                                    (!listing.disputed) && (
                                                                        <div className="icon">
                                                                            <span>Completed</span>
                                                                            <i className={ "fas fa-box-check" + (listing.completed ? " active" : "") }></i>
                                                                        </div>
                                                                    )
                                                                }

                                                                <div className="icon">
                                                                    <span>Claimed</span>
                                                                    <i className={ "fas fa-wallet" + (listing.claimed ? " active" : "") }></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="actions">
                                                        <button type="submit" onClick={ e => handleOrderDetails(listing) }>View Order Details</button>
                                                        {
                                                            (!listing.completed && !listing.disputed) && (
                                                                <button type="submit" onClick={ e => handleReceived(listing.orderNumber, e.target) }>{ textStrings["mark_received"][state.language] }</button>
                                                            )
                                                        }
                                                        {
                                                            (!listing.completed && !listing.claimed && !listing.disputed && !listing.extended) && (
                                                                <button type="submit" onClick={ e => handleExtend(listing.orderNumber, e.target) }>Extend Escrow</button>
                                                            )
                                                        }
                                                        {
                                                            (!listing.completed && !listing.claimed && !listing.disputed) && (
                                                                <button type="submit" onClick={ e => handleDispute(listing, e.target) }>Dispute</button>
                                                            )
                                                        }
                                                        {
                                                            (listing.disputed) && (
                                                                <Link to="/account/arbitration"><button type="submit">View Dispute</button></Link>
                                                            )
                                                        }
                                                        {
                                                            (listing.completed && !listing.feedback) && (
                                                                <button type="submit" onClick={ e => handleReview(listing, e.target) }>Leave Review</button>
                                                            )
                                                        }
                                                    </div>
                                                </li>
                                            </Fragment>
                                        ))
                                    }

                                    <div className="pages">
                                        {
                                            (data?.rawOrders?.length > 60 && inputs.selectedPage > 3) && (
                                                <i onClick={ () => handlePage(0) } className="fal fa-chevron-double-left"></i>
                                            )
                                        }

                                        {
                                            (data?.rawOrders?.length > 60) && (
                                                [...Array(Math.ceil(data?.rawOrders?.length / 10))].map((i, index) => (
                                                    (index - inputs.selectedPage <= 3 && index - inputs.selectedPage >= -3) ? <button className={ inputs.selectedPage == index ? "active" : "" } onClick={ () => handlePage(index) }>{ index + 1 }</button> : ""
                                                ))
                                            )
                                        }

                                        {
                                            (data?.rawOrders?.length > 60 && inputs.selectedPage < Math.ceil(data?.rawOrders?.length / 10)-4) && (
                                                <i onClick={ () => handlePage(Math.ceil(data?.rawOrders?.length / 10)-1) } className="fal fa-chevron-double-right"></i>
                                            )
                                        }

                                        {
                                            (data?.rawOrders?.length < 60) && (
                                                [...Array(Math.ceil(data?.rawOrders?.length / 10))].map((i, index) => (
                                                    <button className={ inputs.selectedPage == index ? "active" : "" } onClick={ () => handlePage(index) }>{ index + 1 }</button>
                                                ))
                                            )
                                        }
                                    </div>
                                </ul>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Orders;
