import { React, useState, useEffect, useMemo, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";
import * as CryptoJS from "crypto-js";

import { useStateValue } from "state";
import { countryCodes, countryCodesDictionary, regionDictionary, dummyCategories, dummyWeeklyDeal, supportedCurrencies } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Proton from "assets/javascript/proton";

import Breadcrumb from "components/breadcrumb"
import Sidebar from "components/account/sidebar";

function Arbitration() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({ selectedPage: 0, votedFilter: false, resolvedFilter: false });
    const [data, setData] = useState({ });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) {
            dispatch({
                type: "promptLogin",
                value: "/account/arbitration"
            });
            
            return navigate("/");
        };

        if (state.authenticated) return handleDataFetch();
    }, [state.authenticated]);

    useEffect(() => {
        if (state.fetchDisputes) {
            dispatch({
                type: "fetchDisputes",
                value: false
            });

            setTimeout(() => {
                return handleDataFetch();
            }, 3000);
        };
    }, [state.fetchDisputes]);

    function handleDataFetch() {
        var fetchSuccess = (res) => {
            var disputeArray = [];

            checkArbitratorStatus();

            if (res.length < 1) return setData(values => (
                { ...values, disputes: disputeArray, cases: [] }
            ));

            // potentially sort disputes ascending order and do 1 table query with lower/upper bound + filter relevant disputes (less likely to hit rate limits)
            res.forEach(async (d, index) => {
                const result = await Proton.fetchDispute(d.disputeId);

                if (!result) return setTimeout(() => {
                    handleDataFetch();
                }, 3000);

                disputeArray.push({
                    ...d,
                    disputer: result?.disputer,
                    disputeCreated: result?.created,
                    disputeReason: result?.reason,
                    disputeProposals: result?.proposals,
                    disputeResolved: result?.resolved,
                });

                if (disputeArray.length == res.length) {
                    setData(values => (
                        { ...values, disputes: disputeArray?.sort((a, b) => a.created - b.created) }
                    ));
                };
            });
        };

        var fetchError = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error authenticating your request, please try again");
        };

        Listing.fetchDisputes(state.session.auth.actor).then(res => {
            fetchSuccess(res);
        }).catch(err => {
            var { message } = err.responseJSON || { message: err };

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Listing.fetchDisputes(state.session.auth.actor).then(res => {
                        fetchSuccess(res);
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err };

                        fetchError(message);
                    });
                }).catch(err => {
                    $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

                    navigate("/");
                });
            } else {
                fetchError(message);
            };
        });
    };

    function handleCases(a) {
        if (a.suspension > Date.now() / 1000) return;

        if (a.active_disputes.length < 1) return;

        var fetchSuccess = (res) => {
            var caseArray = [];

            // potentially sort disputes ascending order and do 1 table query with lower/upper bound + filter relevant disputes (less likely to hit rate limits)
            a.active_disputes.forEach(async (disputeId, index) => {
                var result = await Proton.fetchDispute(disputeId);

                if (!result) return setTimeout(() => {
                    handleCases(a);
                }, 3000);

                var purchaseObj = res?.filter((r => r.disputeId == result.dispute_id));

                caseArray.push({ ...purchaseObj?.[0], ...result });

                if (caseArray.length == a.active_disputes.length) {
                    var finalCases = caseArray?.filter(c => Date.now() / 1000 > c.created + 60*60*24*7 && c.buyerId != state.session.auth.actor && c.sellerId != state.session.auth.actor)?.sort((a, b) => a.created - b.created);

                    setData(values => (
                        { ...values, rawCasesConst: finalCases, rawCases: finalCases, cases: finalCases?.slice(inputs.selectedPage * 10, (inputs.selectedPage * 10) + 10) }
                    ));

                    if (inputs.votedFilter) return handleFilter("voted", true, finalCases);
                    if (inputs.resolvedFilter) return handleFilter("resolved", true, finalCases);
                };
            });
        };

        var fetchError = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error authenticating your request, please try again");
        };

        Listing.fetchCases(state.session.auth.actor, a.active_disputes).then(res => {
            fetchSuccess(res);
        }).catch(err => {
            var { message } = err.responseJSON || { message: err };

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Listing.fetchCases(state.session.auth.actor, a.active_disputes).then(res => {
                        fetchSuccess(res);
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err };

                        fetchError(message);
                    });
                }).catch(err => {
                    $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

                    navigate("/");
                });
            } else {
                fetchError(message);
            };
        });
    };

    async function checkArbitratorStatus() {
        const result = await Proton.fetchArbitratorStatus(state.session.auth.actor);

        if (result?.active) handleCases(result);

        setData(values => (
            { ...values, arbitrator: result }
        ));
    };

    function handleOrderDetails(listing) {
        $("div.input-modal").addClass("active").find("section.order-details").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "order-details",
                listing: listing
            }
        });
    };

    function handleViewEvidence(listing) {
        $("div.input-modal").addClass("active").find("section.view-evidence").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "view-evidence",
                listing: listing
            }
        });
    };

    function handleVote(listing) {
        $("div.input-modal").addClass("active").find("section.vote-dispute").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "vote-dispute",
                listing: listing
            }
        });
    };

    function handleUpload(dispute, button) {
        $(button).html("<i class='fad fa-spinner-third'></i> Uploading");
        
        $("div.input-modal").addClass("active").find("section.upload-evidence").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "upload-evidence",
                data: {
                    listing: dispute,
                    button: button
                }
            }
        });
    };

    function handleCancelDispute(disputeId, button) {
        $(button).html("<i class='fad fa-spinner-third'></i> Cancelling");

        var showSuccess = () => {
            $("div.error-modal").addClass("success").find("div.text").html("Dispute has been successfully cancelled");

            $(button).html("Cancel Dispute");

            setTimeout(() => {
                handleDataFetch();
            }, 1000);
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");

            $(button).html("Cancel Dispute");
        };

        Proton.cancelDispute(state, disputeId).then(tx => {
            Listing.cancelDispute(state.session.auth.actor, disputeId, (tx.serializedTransaction || tx.resolved.serializedTransaction), tx.signatures, tx.signer || {"actor":state.session.auth.actor,"permission":"active"}, tx.resolvedTransaction).then(res => {
                showSuccess(res);
            }).catch(err => {
                var { message } = err.responseJSON || { message: err };
    
                if (message?.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Listing.cancelDispute(state.session.auth.actor, disputeId, (tx.serializedTransaction || tx.resolved.serializedTransaction), tx.signatures, tx.signer || {"actor":state.session.auth.actor,"permission":"active"}, tx.resolvedTransaction).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            var { message } = err.responseJSON || { message: err };
    
                            showError(message);
                        });
                    }).catch(err => {
                        $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");
    
                        navigate("/");
                    });
                } else {
                    showError(message);
                };
            });
        }).catch(err => {
            showError(err.error?.details?.[0]?.message || err);
        });
    };

    function handleResolveDispute(disputeId, button) {
        $(button).html("<i class='fad fa-spinner-third'></i> Resolving");

        var showSuccess = (txId) => {
            $("div.error-modal").addClass("success").find("div.text").html("Dispute has been successfully resolved</br></br><a href='https://explorer.xprnetwork.org/transaction/" + txId + "' target='_blank'>View Transaction</a>");

            $(button).html("Resolve Dispute");

            setTimeout(() => {
                handleDataFetch();
            }, 1000);
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");

            $(button).html("Resolve Dispute");
        };

        Proton.resolveDispute(state, disputeId).then(tx => {
            showSuccess(tx.processed.id);
        }).catch(err => {
            showError(err.error?.details?.[0]?.message || err);
        });
    };

    function handleLeavePanel(button) {
        $(button).html("<i class='fad fa-spinner-third'></i> Leaving");

        var showSuccess = () => {
            $("div.error-modal").addClass("success").find("div.text").html("You have left the Arbitration Panel and been refunded your entrance fee");

            $(button).html("Leave Panel");

            setTimeout(() => {
                handleDataFetch();
            }, 1000);
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");

            $(button).html("Leave Panel");
        };

        Proton.leavePanel(state).then(tx => {
            showSuccess();
        }).catch(err => {
            showError(err.error?.details?.[0]?.message || err);
        });
    };

    function handleJoinPanel(button) {
        if (button.localName == "i") button = button.parentElement;

        $(button).html("<i class='fad fa-spinner-third'></i> Joining");

        var showSuccess = () => {
            $(button).html("Join Now <i class='fal fa-long-arrow-right'></i>");

            setTimeout(() => {
                handleDataFetch();
            }, 1000);
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");

            $(button).html("Join Now <i class='fal fa-long-arrow-right'></i>");
        };

        Proton.joinPanel(state).then(tx => {
            showSuccess();
        }).catch(err => {
            showError(err.error?.details?.[0]?.message || err);
        });
    };

    function handleCountdown(secondsLeft) {
        if (secondsLeft <= 0) return (
            <><i className="fal fa-clock"></i> Finished</>
        );

        var days = Math.floor(secondsLeft / (60 * 60 * 24));
        var hours = Math.floor((secondsLeft % (60 * 60 * 24)) / (60 * 60));
        var minutes = Math.floor((secondsLeft % (60 * 60)) / (60));
        var seconds = Math.floor((secondsLeft % (60)));

        var plural = "";

        if (days > 0) {
            if (days > 1) plural = "s";

            return (
                <><i className="fal fa-clock"></i> { days + " day" + plural }</>
            );
        } else if (hours > 0) {
            if (hours > 1) plural = "s";

            return (
                <><i className="fal fa-clock"></i> { hours + " hour" + plural }</>
            );
        } else if (minutes > 0) {
            if (minutes > 1) plural = "s";

            return (
                <><i className="fal fa-clock"></i> { minutes + " minute" + plural }</>
            );
        } else if (seconds > 0) {
            if (seconds > 1) plural = "s";

            return (
                <><i className="fal fa-clock"></i> { seconds + " second" + plural }</>
            );
        };
    };

    function handleVerdict(dispute) {
        const buyerVotes = dispute.votes?.filter(v => v.proposer == dispute.buyerId);

        const sellerVotes = dispute.votes?.filter(v => v.proposer == dispute.sellerId);

        var verdict = (buyerVotes?.length > sellerVotes?.length) ? "Buyer" : "Seller";

        return (
            <><i className="fal fa-gavel"></i> { verdict }</>
        );
    };

    function handlePage(index) {
        setInputs(values => (
            { ...values, selectedPage: index }
        ));

        setData(values => (
            { ...values, cases: data?.rawCases?.slice(index * 10, (index + 1) * 10) }
        ));

        $("body, html").animate({ scrollTop: $(".arbitration-panel").offset().top - 24 }, 0);
    };

    function handleFilter(filter, active, latestCases) {
        var rawCases = (latestCases) ? latestCases : data.rawCasesConst;

        if (filter == "voted") {
            var rawCasesFiltered = (active) ? rawCases?.filter(c => c.votes.every(v => v.arbitrator != state.session.auth.actor) && Date.now() / 1000 > c.created + 60*60*24*7 && Date.now() / 1000 < c.created + 60*60*24*14) : rawCases;

            setData(values => (
                { ...values, rawCases: rawCasesFiltered, cases: rawCasesFiltered?.slice(0, 10) || [] }
            ));

            setInputs(values => (
                { ...values, votedFilter: active, resolvedFilter: false, selectedPage: 0 }
            ));
        };

        if (filter == "resolved") {
            var rawCasesFiltered = (active) ? rawCases?.filter(c => Date.now() / 1000 < c.created + 60*60*24*21) : rawCases;

            setData(values => (
                { ...values, rawCases: rawCasesFiltered, cases: rawCasesFiltered?.slice(0, 10) || [] }
            ));

            setInputs(values => (
                { ...values, votedFilter: false, resolvedFilter: active, selectedPage: 0 }
            ));
        };
    };

    const breadcrumbs = [
        { label: "Home", path: "/" },
        { label: textStrings["my_account"][state.language], path: "/account" },
        { label: textStrings["account_arbitration"][state.language], path: "/account/arbitration" }
    ]

    return (
        <section className="account">
            <div className="page-header">My Account</div>

            <div className="container">
                <div className="breadcrumb-wrapper border-bottom">
                    <div className="breadcrumb">
                        <Breadcrumb crumbs={breadcrumbs} />
                    </div>
                </div>

                <div className="account-wrapper">
                    <Sidebar />

                    <div className="content-wrapper">
                        <section className="my-disputes">
                            <div className="content-title-area">
                                <div className="content-title"><i className="fal fa-gavel"></i> { textStrings?.["account_my_disputes"]?.[state.language] }</div>
                            </div>

                            <div className="my-disputes">
                                <ul>
                                    {
                                        (data.disputes == null) && (
                                            <div className="loading">
                                                <i className="fad fa-spinner-third"></i>

                                                <span>Fetching disputes</span>
                                            </div>
                                        )
                                    }

                                    {
                                        (data.disputes?.length < 1) && (
                                            <>
                                                <div key={ 1 } className="empty">You are not involved in any active disputes</div>
                                                <span key={ 2 } className="empty"><Link to="/account/orders">Go to orders</Link> to create a dispute</span>
                                            </>
                                        )
                                    }

                                    {
                                        data.disputes?.map((dispute, index) => (
                                            <Fragment key={ index }>
                                                <div className="dispute-overview">
                                                    <div className="stat">
                                                        <div className="subtitle">Dispute Number</div>
                                                        <div className="value">{ dispute.disputeId }</div>
                                                    </div>
                                                    <div className="stat">
                                                        <div className="subtitle">Dispute Date</div>
                                                        <div className="value">{ new Date(dispute.disputeCreated * 1000).toLocaleDateString() }, { new Date(dispute.disputeCreated * 1000).toLocaleTimeString()?.slice(0, 5) }</div>
                                                    </div>
                                                    <div className="stat">
                                                        <div className="subtitle">Order Number</div>
                                                        <div className="value">{ dispute.orderNumber }</div>
                                                    </div>
                                                    <div className="stat">
                                                        <div className="subtitle">Counterparty</div>
                                                        <div className="value"><Link to={ "/profile/" + (dispute.sellerId == state.session.auth.actor ? dispute.buyerId : dispute.sellerId) }>{ (dispute.sellerId == state.session.auth.actor ? dispute.buyerId : dispute.sellerId) }</Link> <Link to={ "/messages/" + (dispute.sellerId == state.session.auth.actor ? dispute.buyerId : dispute.sellerId) }><i className="fal fa-envelope message"></i></Link></div>
                                                    </div>
                                                    <div className="stat">
                                                        <div className="subtitle">Order Total</div>
                                                        <div className="value">
                                                            { ((parseFloat(dispute.purchasedVariation.price) * dispute.purchasedQuantity) + parseFloat(dispute.purchasedShipping || 0)).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }
                                                            {
                                                                (dispute.price != dispute.purchasedVariation.price) && (
                                                                    <i className="fal fa-redo" title={ "$" + (dispute.price.split(" ")[0] - dispute.purchasedVariation.price.split(" ")[0]).toFixed(2) + " has been refunded" }></i>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <li>
                                                    <Link to={ "/listing/" + CryptoJS.MD5(dispute.listingId).toString() }>
                                                        <div className="thumbnail-wrapper">
                                                            <img src={ dispute.thumbnail } alt={ dispute.title } />
                                                        </div>
                                                    </Link>

                                                    <div className="info">
                                                        <Link to={ "/listing/" + CryptoJS.MD5(dispute.listingId).toString() }><div className="title">{ dispute.title }</div></Link>
                                                        <div className="price">{ parseFloat(dispute.price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) } <span>({ dispute.purchasedCurrency })</span></div>
                                                        <div className="options">
                                                            {
                                                                (dispute.purchasedVariation.option0) && (
                                                                    <span>{ dispute.purchasedVariation.option0 }</span>
                                                                )
                                                            }
                                                            {
                                                                (dispute.purchasedVariation.option1) && (
                                                                    <span> / { dispute.purchasedVariation.option1 }</span>
                                                                )
                                                            }
                                                            {
                                                                (dispute.purchasedVariation.option2) && (
                                                                    <span> / { dispute.purchasedVariation.option2 }</span>
                                                                )
                                                            }
                                                        </div>
                                                        <div className="reason" dangerouslySetInnerHTML={{ __html: "'" + decodeURIComponent((dispute.disputeNotes?.[0]?.note || "No note added")?.replaceAll("<script>", "").replaceAll("</script>", "").replaceAll("<script/>", "").replaceAll("%0A", "</br>").replaceAll("undefined", "No note added")) + "'" }}/>
                                                        <div className="status-icons">
                                                            <div className="section">
                                                                <div className="icon">
                                                                    <span>{ (Date.now() / 1000 > dispute.disputeCreated + 60*60*24*21) ? "Verdict" : "Next Phase" }</span>
                                                                    <p>{ (Date.now() / 1000 > dispute.disputeCreated + 60*60*24*21) ? handleVerdict(dispute) : handleCountdown(((dispute.disputeCreated + 60*60*24*21) - (Date.now() / 1000)) % 604800) }</p>
                                                                </div>
                                                            </div>

                                                            <div className="section">
                                                                <div className="icon" title={ (Date.now() / 1000 > dispute.disputeCreated + 60*60*24*7) ? "Complete" : "Pending" }>
                                                                    <span>Evidence</span>
                                                                    <i className={ (Date.now() / 1000 > dispute.disputeCreated + 60*60*24*7) ? "fas fa-folders complete" : "fas fa-folders pending" }></i>
                                                                </div>

                                                                <div className="icon" title={ (Date.now() / 1000 > dispute.disputeCreated + 60*60*24*14) ? "Complete" : (Date.now() / 1000 > dispute.disputeCreated + 60*60*24*7 && Date.now() / 1000 < dispute.disputeCreated + 60*60*24*14) ? "Pending" : "Not Started" }>
                                                                    <span>Voting</span>
                                                                    <i className={ (Date.now() / 1000 > dispute.disputeCreated + 60*60*24*14) ? "fas fa-box-ballot complete" : (Date.now() / 1000 > dispute.disputeCreated + 60*60*24*7 && Date.now() / 1000 < dispute.disputeCreated + 60*60*24*14) ? "fas fa-box-ballot pending" : "fas fa-box-ballot" }></i>
                                                                </div>

                                                                <div className="icon" title={ (Date.now() / 1000 > dispute.disputeCreated + 60*60*24*21) ? "Complete" : (Date.now() / 1000 > dispute.disputeCreated + 60*60*24*14 && Date.now() / 1000 < dispute.disputeCreated + 60*60*24*21) ? "Pending" : "Not Started" }>
                                                                    <span>Review</span>
                                                                    <i className={ (Date.now() / 1000 > dispute.disputeCreated + 60*60*24*21) ? "fas fa-search complete" : (Date.now() / 1000 > dispute.disputeCreated + 60*60*24*14 && Date.now() / 1000 < dispute.disputeCreated + 60*60*24*21) ? "fas fa-search pending" : "fas fa-search" }></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="actions">
                                                        <button type="submit" onClick={ e => handleOrderDetails(dispute) }>View Order Details</button>
                                                        {
                                                            (Date.now() / 1000 < dispute.disputeCreated + 60*60*24*7) && (
                                                                <button type="submit" onClick={ e => handleUpload(dispute, e.target) }>Upload Evidence</button>
                                                            )
                                                        }
                                                        {
                                                            (dispute.disputer == state.session.auth.actor) && (
                                                                <button type="submit" onClick={ e => handleCancelDispute(dispute.disputeId, e.target) }>Cancel Dispute</button>
                                                            )
                                                        }
                                                        {
                                                            (Date.now() / 1000 > dispute.disputeCreated + 60*60*24*21 && !dispute.disputeResolved) && (
                                                                <button type="submit" onClick={ e => handleResolveDispute(dispute.disputeId, e.target) }>Resolve Dispute</button>
                                                            )
                                                        }
                                                    </div>
                                                </li>
                                            </Fragment>
                                        ))
                                    }
                                </ul>
                            </div>
                        </section>

                        {
                            (!data.arbitrator?.active) && (
                                <section className="join-panel">
                                    <div className="join-panel">
                                        <div className={ "banner-wrapper arbitration" }>
                                            <div className="category-subtitle">Entrance Fee: 100,000 STRX</div>
                                            <div className="category-title">Join the Arbitration Panel</div>
                                            <div className="buttons">
                                                <button onClick={ e => handleJoinPanel(e.target) }>Join Now <i className="fal fa-long-arrow-right"></i></button>
                                                <a href="https://storex.medium.com/storex-deep-dive-3-arbitration-panel-77d21017cdcd" target="_blank"><button className="secondary">Learn More</button></a>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            )
                        }

                        {
                            (data.arbitrator?.active) && (
                                <section className="arbitration-panel">
                                    <div className="arbitration-panel">
                                        <div className="content-title-area">
                                            <div className="content-title"><i className="fal fa-file-search"></i> { textStrings?.["account_my_cases"]?.[state.language] }</div>
                                            <span className="filters">
                                                <button className={ (inputs.votedFilter ? "active" : "") } onClick={ () => handleFilter("voted", !inputs.votedFilter) }>Not Voted</button>
                                                <button className={ (inputs.resolvedFilter ? "active" : "") } onClick={ () => handleFilter("resolved", !inputs.resolvedFilter) }>Not Resolved</button>
                                            </span>
                                        </div>

                                        <div className="summary">
                                            <div className="top-wrapper">
                                                <div className="left">
                                                    <div className="title">Arbitration Panel</div>
                                                    <div className="subtitle">Locked Fee: { parseFloat(data.arbitrator?.locked?.quantity?.split(" ")?.[0]).toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 4})?.slice(1) } { data.arbitrator?.locked?.quantity?.split(" ")?.[1] }</div>
                                                </div>

                                                <div className="right">
                                                    <div className="subtitle active">Status: Active <i className="fas fa-circle"></i></div>
                                                </div>
                                            </div>

                                            <div className="bottom-wrapper">
                                                <div className="left">
                                                    <div className="rewards">
                                                        <div>
                                                            <div className="title">Estimated Total Rewards</div>
                                                            <div className="subtitle">{ (0).toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 4, maximumFractionDigits: 4})?.slice(1) } <img src={ supportedCurrencies[0].logo } alt={ supportedCurrencies[0].ticker }/></div>
                                                        </div>

                                                        <button className="claim">Claim Rewards</button>
                                                    </div>
                                                </div>

                                                <div className="right">
                                                    <button className="leave" onClick={ e => handleLeavePanel(e.target) }>Leave Panel</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="active-cases">
                                            <ul>
                                                {
                                                    (data.cases == null) && (
                                                        <div className="loading">
                                                            <i className="fad fa-spinner-third"></i>

                                                            <span>Fetching cases</span>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    (data.cases?.length < 1) && (
                                                        <>
                                                            <div key={ 1 } className="empty">You are not involved in any active cases</div>
                                                            <span key={ 2 } className="empty">Wait until you are randomly drawn as an arbitrator</span>
                                                        </>
                                                    )
                                                }

                                                {
                                                    data.cases?.map((dispute, index) => (
                                                        <Fragment key={ index }>
                                                            <div className="dispute-overview" key={ index+9 }>
                                                                <div className="stat">
                                                                    <div className="subtitle">Dispute Number</div>
                                                                    <div className="value">{ dispute.disputeId }</div>
                                                                </div>
                                                                <div className="stat">
                                                                    <div className="subtitle">Dispute Date</div>
                                                                    <div className="value">{ new Date(dispute.created * 1000).toLocaleDateString() }, { new Date(dispute.created * 1000).toLocaleTimeString()?.slice(0, 5) }</div>
                                                                </div>
                                                                <div className="stat">
                                                                    <div className="subtitle">Order Number</div>
                                                                    <div className="value">{ dispute.orderNumber }</div>
                                                                </div>
                                                                <div className="stat">
                                                                    <div className="subtitle">Seller</div>
                                                                    <div className="value">{ dispute.sellerId }</div>
                                                                </div>
                                                                <div className="stat">
                                                                    <div className="subtitle">Order Total</div>
                                                                    <div className="value">
                                                                        { ((parseFloat(dispute.purchasedVariation.price) * dispute.purchasedQuantity) + parseFloat(dispute.purchasedShipping || 0)).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }
                                                                        {
                                                                            (dispute.price != dispute.purchasedVariation.price) && (
                                                                                <i className="fal fa-redo" title={ "$" + (dispute.price.split(" ")[0] - dispute.purchasedVariation.price.split(" ")[0]).toFixed(2) + " has been refunded" }></i>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
            
                                                            <li key={ index+999 }>
                                                                <Link to={ "/listing/" + CryptoJS.MD5(dispute.listingId).toString() }>
                                                                    <div className="thumbnail-wrapper">
                                                                        <img src={ dispute.thumbnail } alt={ dispute.title } />
                                                                    </div>
                                                                </Link>
            
                                                                <div className="info">
                                                                    <Link to={ "/listing/" + CryptoJS.MD5(dispute.listingId).toString() }><div className="title">{ dispute.title }</div></Link>
                                                                    <div className="price">{ parseFloat(dispute.price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) } <span>({ dispute.purchasedCurrency })</span></div>
                                                                    <div className="options">
                                                                        {
                                                                            (dispute.purchasedVariation.option0) && (
                                                                                <span>{ dispute.purchasedVariation.option0 }</span>
                                                                            )
                                                                        }
                                                                        {
                                                                            (dispute.purchasedVariation.option1) && (
                                                                                <span> / { dispute.purchasedVariation.option1 }</span>
                                                                            )
                                                                        }
                                                                        {
                                                                            (dispute.purchasedVariation.option2) && (
                                                                                <span> / { dispute.purchasedVariation.option2 }</span>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className="reason" dangerouslySetInnerHTML={{ __html: "'" + decodeURIComponent((dispute.disputeNotes?.[0]?.note || "No note added")?.replaceAll("<script>", "").replaceAll("</script>", "").replaceAll("<script/>", "").replaceAll("%0A", "</br>").replaceAll("undefined", "No note added")) + "'" }}/>
                                                                    <div className="status-icons">
                                                                        <div className="section">
                                                                            <div className="icon">
                                                                                <span>Votes Cast</span>
                                                                                <p><i className="fal fa-box-ballot"></i> { dispute.votes.length }</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="section">
                                                                            <div className="icon">
                                                                                <span>{ (Date.now() / 1000 > dispute.created + 60*60*24*21) ? "Verdict" : "Next Phase" }</span>
                                                                                <p>{ (Date.now() / 1000 > dispute.created + 60*60*24*21) ? handleVerdict(dispute) : handleCountdown(((dispute.created + 60*60*24*21) - (Date.now() / 1000)) % 604800) }</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="section">
                                                                            <div className="icon" title={ (Date.now() / 1000 > dispute.created + 60*60*24*7) ? "Complete" : "Pending" }>
                                                                                <span>Evidence</span>
                                                                                <i className={ (Date.now() / 1000 > dispute.created + 60*60*24*7) ? "fas fa-folders complete" : "fas fa-folders pending" }></i>
                                                                            </div>

                                                                            <div className="icon" title={ (Date.now() / 1000 > dispute.created + 60*60*24*14) ? "Complete" : (Date.now() / 1000 > dispute.created + 60*60*24*7 && Date.now() / 1000 < dispute.created + 60*60*24*14) ? "Pending" : "Not Started" }>
                                                                                <span>Voting</span>
                                                                                <i className={ (Date.now() / 1000 > dispute.created + 60*60*24*14) ? "fas fa-box-ballot complete" : (Date.now() / 1000 > dispute.created + 60*60*24*7 && Date.now() / 1000 < dispute.created + 60*60*24*14) ? "fas fa-box-ballot pending" : "fas fa-box-ballot" }></i>
                                                                            </div>

                                                                            <div className="icon" title={ (Date.now() / 1000 > dispute.created + 60*60*24*21) ? "Complete" : (Date.now() / 1000 > dispute.created + 60*60*24*14 && Date.now() / 1000 < dispute.created + 60*60*24*21) ? "Pending" : "Not Started" }>
                                                                                <span>Review</span>
                                                                                <i className={ (Date.now() / 1000 > dispute.created + 60*60*24*21) ? "fas fa-search complete" : (Date.now() / 1000 > dispute.created + 60*60*24*14 && Date.now() / 1000 < dispute.created + 60*60*24*21) ? "fas fa-search pending" : "fas fa-search" }></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
            
                                                                <div className="actions">
                                                                    <button type="submit" onClick={ () => handleOrderDetails(dispute) }>View Order Details</button>
                                                                    <button type="submit" onClick={ () => handleViewEvidence(dispute) }>View Evidence</button>
                                                                    {
                                                                        (Date.now() / 1000 > dispute.created + 60*60*24*7 && Date.now() / 1000 < dispute.created + 60*60*24*14 && dispute.votes?.filter(v => v.arbitrator == state.session.auth.actor)?.length < 1) && (
                                                                            <button type="submit" onClick={ () => handleVote(dispute) }>Vote</button>
                                                                        )
                                                                    }
                                                                    {
                                                                        (Date.now() / 1000 > dispute.created + 60*60*24*21 && !dispute.resolved) && (
                                                                            <button type="submit" onClick={ e => handleResolveDispute(dispute.disputeId, e.target) }>Resolve Dispute</button>
                                                                        )
                                                                    }
                                                                </div>
                                                            </li>
                                                        </Fragment>
                                                    ))
                                                }

                                                    <div className="pages">
                                                        {
                                                            (data?.rawCases?.length > 60 && inputs.selectedPage > 3) && (
                                                                <i onClick={ () => handlePage(0) } className="fal fa-chevron-double-left"></i>
                                                            )
                                                        }

                                                        {
                                                            (data?.rawCases?.length > 60) && (
                                                                [...Array(Math.ceil(data?.rawCases?.length / 10))].map((i, index) => (
                                                                    (index - inputs.selectedPage <= 3 && index - inputs.selectedPage >= -3) ? <button className={ inputs.selectedPage == index ? "active" : "" } onClick={ () => handlePage(index) }>{ index + 1 }</button> : ""
                                                                ))
                                                            )
                                                        }

                                                        {
                                                            (data?.rawCases?.length > 60 && inputs.selectedPage < Math.ceil(data?.rawCases?.length / 10)-4) && (
                                                                <i onClick={ () => handlePage(Math.ceil(data?.rawCases?.length / 10)-1) } className="fal fa-chevron-double-right"></i>
                                                            )
                                                        }

                                                        {
                                                            (data?.rawCases?.length < 60) && (
                                                                [...Array(Math.ceil(data?.rawCases?.length / 10))].map((i, index) => (
                                                                    <button className={ inputs.selectedPage == index ? "active" : "" } onClick={ () => handlePage(index) }>{ index + 1 }</button>
                                                                ))
                                                            )
                                                        }
                                                    </div>
                                            </ul>
                                        </div>
                                    </div>
                                </section>
                            )
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Arbitration;
