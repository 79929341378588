import { Link } from "react-router-dom"

import BlogPost from "./blog-post"

const blogPosts = [
    { title: "Storex Roadmap 2024", author: "Storex", authorLink: "/", published: { month: "JAN", day: "15" }, link: "https://storex.medium.com/storex-roadmap-2024-the-year-of-release-43f1be6ede87", image: "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80" },
    { title: "Storex Year in Review 2023", author: "Storex", authorLink: "/", published: { month: "DEC", day: "29" }, link: "https://storex.medium.com/storex-year-in-review-2023-a-year-of-innovation-and-milestones-200834713125", image: "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80" },
    { title: "Integrating the Proton Ecosystem", author: "Storex", authorLink: "/", published: { month: "NOV", day: "23" }, link: "https://storex.medium.com/storex-deep-dive-4-integrating-the-proton-ecosystem-b5fd01b804df", image: "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80" },
    { title: "Arbitration Panel", author: "Storex", authorLink: "/", published: { month: "OCT", day: "28" }, link: "https://storex.medium.com/storex-deep-dive-3-arbitration-panel-77d21017cdcd", image: "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80" }
]

export default function BlogPosts() {

    return (
        <div className="blog n1280">
            <div className="container">
                <div className="section-title">Our Blog <a href="https://storex.medium.com" target="_blank" rel="noreferrer">View All Posts <i className="fal fa-long-arrow-right" /></a></div>

                <div className="blog-wrapper">
                    {
                        blogPosts.map((post, index) => (
                            <BlogPost key={index} {...post} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}