import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { countryCodes, regionCodesDictionary, countryCodesDictionary, regionDictionary, dummyCategories, dummyWeeklyDeal, dummyListings } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Cart from "assets/javascript/cart";
import * as Proton from "assets/javascript/proton";

import Breadcrumb from "components/breadcrumb";

function AdminPanel() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({  });
    const [data, setData] = useState({  });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    const breadcrumbs = [
        { label: "Home", path: "/" },
        { label: textStrings["shipping_zones"][state.language], path: "/shipping-zones" }
    ]

    return (
        <section className="admin-panel">
            <div className="page-header">Admin Panel</div>

            <div className="container">
                <div className="breadcrumb-wrapper">
                    <div className="breadcrumb">
                        <Breadcrumb crumbs={breadcrumbs} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AdminPanel;
