import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { countryCodes, countryCodesDictionary, regionDictionary, kycStatus } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Proton from "assets/javascript/proton";

import Breadcrumb from "components/breadcrumb";
import Sidebar from "components/account/sidebar";

function Settings() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({ loaded: false });
    const [data, setData] = useState({ chainCheck: false, apiCheck: false, kyc: false, pending: false, jwt: null, sessions: [] });
    const [state, dispatch] = useStateValue();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) {
            dispatch({
                type: "promptLogin",
                value: "/account/settings"
            });
            
            return navigate("/");
        };

        if (state.authenticated) return handleDataFetch();
    }, [state.authenticated]);

    useEffect(() => {
        if (!state.account.email.address) return;

        if (state.w3aConnected) {
            handleKYC(null, true);
        };
    }, [state.account.email.address]);

    useEffect(() => {
        if (!state.emailVerified) return;

        if (state.emailVerified) {
            setData(values => (
                { ...values, mfa: false }
            ));
        };
    }, [state.emailVerified]);

    useEffect(() => {
        if (!state.kycSubmitted) return;

        if (state.kycSubmitted) {
            if (state.w3aConnected) {
                handleKYC(null, true);
            };

            setTimeout(() => {
                handleDataFetch();
            }, 3000);
        };
    }, [state.kycSubmitted]);

    function populateContent(content) {
        var avatar = content.avatar || "/assets/images/default_avatar.webp";
        var displayName = content.displayName || "";
        var fullName = content.fullName || "";
        var dateBirth = content.dateBirth || "";
        var email = content.email || "";
        var phone = content.phone || "";
        var country = content.country || "";
        var referral = content.referral || "";

        setInputs(values => (
            { ...values, loaded: true, avatar: avatar, displayName: displayName, fullName: fullName, dateBirth: dateBirth, email: email.address, callingCode: phone.callingCode, phoneNumber: phone.phoneNumber, country: country, referral: referral }
        ));

        setData(values => (
            { ...values, sessions: content.sessions }
        ));

        if (email) {
            if (!email.verified) $("input[name='email']").prev().addClass("unverified");
        };

        if (phone) {
            if (!phone.verified) $("input[name='phoneNumber']").prev().addClass("unverified");
        };

        if (phone.callingCode) handleCallingCode({ target: { name: "callingCode", value: phone.callingCode } });
    };

    function handleDataFetch() {
        function checkKYC() {
            Proton.isKycVerified(state.session.auth.actor).then(res => {
                setData(values => (
                    { ...values, kyc: res, chainCheck: true }
                ));
            }).catch(err => {
                setTimeout(() => {
                    checkKYC();
                }, 5000);
            });
        };

        checkKYC();
        
        Account.fetchAccountDetails(state.session.auth.actor).then(res => {
            populateContent(res);
        }).catch(err => {
            var { message } = err.responseJSON;

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.fetchAccountDetails(state.session.auth.actor).then(res => {
                        populateContent(res);
                    }).catch(err => {
                        $("div.error-modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");

                        navigate("/");
                    });
                }).catch(err => {
                    $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

                    navigate("/");
                });
            };
        });
    };

    function handleCallingCode(event) {
        const name = event.target.name;
        const value = event.target.value;

        setInputs(values => (
            { ...values, [name]: value }
        ));

        $("select.calling-code option").first().data("callingCode", value).val(value).text("+" + value);
        $("select.calling-code option").first().removeAttr("selected").attr("selected", "selected");
    };

    function handleChange(event) {
        const name = event.target.name;
        var value = event.target.value;

        if (name == "phoneNumber") value = value.replace(/\D/g, "");

        setInputs(values => (
            { ...values, [name]: value }
        ));

        $(".form-alert").removeClass("active");
    };

    function handleSettings(event) {
        event.preventDefault();

        $("section.settings .form-alert").removeClass("active");
        $("button.settings").html("<i class='fad fa-spinner-third'></i> Saving settings").attr("disabled", true);

        var showSuccess = () => {
            $("div.error-modal").addClass("success").find("div.text").html("Your account details have been saved");

            $("button.settings").html(textStrings["save_changes"][state.language]).attr("disabled", false);
        };

        var showError = () => {
            $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

            $("button.settings").html(textStrings["save_changes"][state.language]).attr("disabled", false);

            navigate("/");
        };

        Account.updateAccountDetails(state.session.auth.actor, inputs).then(res => {
            showSuccess();
        }).catch(err => {
            var { message } = err.responseJSON;

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.updateAccountDetails(state.session.auth.actor, inputs).then(res => {
                        showSuccess();
                    }).catch(err => {
                        var { message } = err.responseJSON;

                        $("section.settings .form-alert").addClass("active").children(".message").html(message);

                        $("button.settings").html(textStrings["save_changes"][state.language]);
                    });
                }).catch(err => {
                    showError();
                });
            } else {
                $("section.settings .form-alert").addClass("active").children(".message").html(message);

                $("button.settings").html(textStrings["save_changes"][state.language]);
            };
        });
    };

    function handleTerminate(event) {
        const sessionId = $(event.target).parent().data("id");

        Account.terminateSession(state.session.auth.actor, sessionId).then(res => {
            $("div.error-modal").addClass("success").find("div.text").html("You have terminated the session");

            $(event.target).parent().remove();
        }).catch(err => {
            var { message } = err.responseJSON;

            if (message.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Account.terminateSession(state.session.auth.actor, sessionId).then(res => {
                        $("div.error-modal").addClass("success").find("div.text").html("You have terminated the session");

                        $(event.target).parent().remove();
                    }).catch(err => {
                        $("div.error-modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
                    });
                }).catch(err => {
                    $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");

                    navigate("/");
                });
            } else {
                $("div.error-modal").addClass("error").find("div.text").html("There was an error processing your request, please try again");
            };
        });
    };

    function handleKYC(event, a) {
        event?.preventDefault();

        if (data.mfa) return $("div.input-modal").addClass("active").find("section.verify-email").addClass("active");

        $("button.verify").html("<i class='fad fa-spinner-third'></i> Checking status").attr("disabled", true);

        var handleSuccess = (res) => {
            // check local variables for previous jwt
            var jwt = (data.jwt) ? data.jwt : res.token;

            if (!jwt) return handleError();

            setData(values => ({ 
                ...values, 
                jwt: jwt 
            }));

            // check metalx kyc status of user
            Proton.metalKYCStatus(jwt).then(res => {
                var { features, status } = res;

                $("button.verify").html(textStrings["begin_verification"]?.[state.language]).attr("disabled", false);

                setData(values => ({ 
                    ...values, 
                    apiCheck: true 
                }));

                // if kyc is unsubmitted open start kyc modal
                if (status == kycStatus.UNSUBMITTED || status == kycStatus.FAILED) {
                    dispatch({
                        type: "setModal",
                        value: {
                            action: "kyc",
                            data: jwt
                        }
                    });
    
                    if (!a) return $("div.input-modal").addClass("active").find("section.kyc").addClass("active");
                };

                // if kyc is pending update the local variable
                if (status == kycStatus.PENDING || status == kycStatus.MANUAL_REVIEW) return setData(values => ({ 
                    ...values, 
                    pending: true 
                }));

                // if kyc is passed update local variable
                if (status == kycStatus.PASSED) return setData(values => ({ 
                    ...values, 
                    kyc: true 
                }));

                // if kyc is failed update local variable
                if (status == kycStatus.FAILED) return setData(values => ({ 
                    ...values, 
                    failed: true
                }));
            }).catch(err => {
                var { error, message } = err.responseJSON || { message: err };
    
                handleError(message, error);
            });
        };

        var handleError = (message, error, data) => {
            if (error == "mfa_required") {
                setData(values => ({ 
                    ...values, 
                    checked: true,
                    mfa: (a) ? true : false,
                    data: (a) ? data : null,
                }));

                dispatch({
                    type: "setModal",
                    value: {
                        action: "verify-email",
                        data: data
                    }
                });

                $("button.verify").html(textStrings["begin_verification"]?.[state.language]).attr("disabled", false);

                if (!a) return $("div.input-modal").addClass("active").find("section.verify-email").addClass("active");

                return;
            };

            $("div.error-modal").addClass("error").find("div.text").html(message + "TIME" + new Date()|| "There was an error authenticating your request, please try again");

            $("button.verify").html(textStrings["begin_verification"]?.[state.language]).attr("disabled", false);
        };

        // if metalx jwt already exists check kyc status
        if (data.jwt) return handleSuccess();

        // sign generateAuth tx and fetch jwt from metalx
        Proton.generateAuth(state).then(res => {
            let email = state.account.email.address;
            let transaction = res.transaction;
            let signatures = res.signatures;
            let signer = state.session.auth;

            Proton.metalLogin(email, signatures, signer, transaction).then(res => {
                handleSuccess(res);
            }).catch(err => {
                var { error, message } = err.responseJSON || { message: err };
    
                handleError(message, error, { email, transaction, signatures, signer });
            });
        }).catch(err => {
            var { message } = err.responseJSON || { message: err };

            handleError(message);
        });
    };

    function handleExportPK(event) {
        event.preventDefault();

        $("div.input-modal").addClass("active").find("section.private-key").addClass("active");
    };

    const breadcrumbs = [
        { label: "Home", path: "/" },
        { label: textStrings["my_account"][state.language], path: "/account" },
        { label: textStrings["account_settings"][state.language], path: "/account/settings" }
    ]

    return (
        <section className="account">
            <div className="page-header">My Account</div>

            <div className="container">
                <div className="breadcrumb-wrapper border-bottom">
                    <div className="breadcrumb">
                        <Breadcrumb crumbs={breadcrumbs} />
                    </div>
                </div>

                <div className="account-wrapper">
                    <Sidebar />

                    <div className="content-wrapper">
                        <section className="settings">
                            <div className="content-title-area">
                                <div className="content-title"><i className="fal fa-user-cog"></i> { textStrings["account_settings"][state.language] }</div>
                            </div>

                            <form onSubmit={ handleSettings }>
                                <div className="input-group">
                                    <label htmlFor="username">Blockchain Username</label>
                                    <i className="fal fa-lock"></i>
                                    <input type="text" name="username" autoComplete="off" value={state.session.auth.actor} onChange={handleChange} aria-label="Blockchain Username" disabled />
                                    <i className={ state.session.auth.actor ? "fad fa-spinner-third hide" : "fad fa-spinner-third" }></i>
                                </div>

                                <div className="input-group">
                                    <label htmlFor="displayName">Display Name</label>
                                    <input type="text" name="displayName" autoComplete="none" maxLength="24" value={inputs.displayName || ""} onChange={handleChange} aria-label="Display name" required />
                                    <i className={ inputs.loaded ? "fad fa-spinner-third hide" : "fad fa-spinner-third" }></i>
                                </div>

                                <div className="input-group">
                                    <label htmlFor="fullName">Full Name <i className="far fa-question-circle" title="Your full legal name will not be made public and will only be used for official purposes"></i> <span className="tooltip">Your full legal name will not be made public and will only be used for official purposes</span></label>
                                    <input type="text" name="fullName" autoComplete="none" value={inputs.fullName || ""} onChange={handleChange} aria-label="Fullname" required />
                                    <i className={ inputs.loaded ? "fad fa-spinner-third hide" : "fad fa-spinner-third" }></i>
                                </div>

                                <div className="input-group date">
                                    <label htmlFor="dateBirth">Date of Birth</label>
                                    <i className="fal fa-calendar"></i>
                                    <input type={inputs.loaded ? "date" : "text"} name="dateBirth" autoComplete="none" min={(new Date().toISOString().split("T")[0].split("-")[0] - 120) + "-" + new Date().toISOString().split("T")[0].split("-")[1] + "-" + new Date().toISOString().split("T")[0].split("-")[2]} max={(new Date().toISOString().split("T")[0].split("-")[0] - 13) + "-" + new Date().toISOString().split("T")[0].split("-")[1] + "-" + new Date().toISOString().split("T")[0].split("-")[2]} value={inputs.dateBirth || ""} onChange={handleChange} aria-label="Date of Birth" required />
                                    <i className={ inputs.loaded ? "fad fa-spinner-third hide" : "fad fa-spinner-third" }></i>
                                </div>

                                <div className="input-group pad-right">
                                    <label htmlFor="email">Email Address</label>
                                    <i className="fal" title="Your email address has not been verified"></i>
                                    <input type="email" name="email" autoComplete="email" value={inputs.email || ""} onChange={handleChange} aria-label="Email address" required />
                                    <i className={ inputs.loaded ? "fad fa-spinner-third hide" : "fad fa-spinner-third" }></i>
                                </div>

                                <div className="input-group pad-right">
                                    <label htmlFor="phone">Phone Number <span className="optional">Optional</span></label>
                                    <div className="input-flex">
                                        <select className="calling-code" name="callingCode" value={inputs.callingCode || ""} onChange={handleCallingCode} aria-label="Phone number country code">
                                            <option value="" style={{ display: "none" }}></option>

                                            {
                                                countryCodes.map((country, index) => (
                                                    <option key={ index } value={ country.callingCode }>{ country.name + " (+" + country.callingCode + ")" }</option>
                                                ))
                                            }
                                        </select>
                                        <i className="fal" title="Your phone number has not been verified"></i>
                                        <input className="phone-number" type="tel" name="phoneNumber" autoComplete="tel" pattern="\d*" value={inputs.phoneNumber || ""} onChange={handleChange} aria-label="Phone number" />
                                    </div>
                                    <i className={ inputs.loaded ? "fad fa-spinner-third hide" : "fad fa-spinner-third" }></i>
                                </div>

                                <div className="input-group">
                                    <label htmlFor="country">Country of Residence</label>
                                    <i className="fal fa-chevron-down"></i>
                                    <select className="country" name="country" value={inputs.country || ""} onChange={handleChange} aria-label="Country" required>
                                        <option value="" style={{ display: "none" }}></option>

                                        {
                                            countryCodes.map((country, index) => (
                                                <option key={ index } value={ country.countryCode }>{ country.name }</option>
                                            ))
                                        }
                                    </select>
                                    <i className={ inputs.loaded ? "fad fa-spinner-third hide" : "fad fa-spinner-third" }></i>
                                </div>

                                <div className="input-group">
                                    <label htmlFor="referral">Referral Code <span className="optional">Optional</span></label>
                                    <input type="text" name="referral" autoComplete="off" value={inputs.referral || ""} onChange={handleChange} aria-label="Referral code" />
                                    <i className={ inputs.loaded ? "fad fa-spinner-third hide" : "fad fa-spinner-third" }></i>
                                </div>

                                <div className="notice">By clicking the <b>{ textStrings["save_changes"][state.language] }</b> button you agree you have read and accepted our <Link to="/user-agreement">User Agreement</Link> and <Link to="/privacy-notice">Privacy Notice</Link> regarding the processing of your data.</div>

                                <button type="submit" className="submit settings">{ textStrings["save_changes"][state.language] }</button>
                            </form>
                        </section>

                        <section className="kyc">
                            <div className="content-title-area">
                                <div className="content-title"><i className="fal fa-id-card"></i> { textStrings["account_identity_verification"]?.[state.language] }</div>
                            </div>

                            <div className="subtitle">Status</div>
                            {
                                (data.failed) && (
                                    <div className="status red"><i className="fal fa-times-circle"></i> Failed</div>
                                )
                            }

                            {
                                (data.pending) && (
                                    <div className="status amber"><i className="fal fa-question-circle"></i> Pending</div>
                                )
                            }

                            {
                                (!data.pending && !data.failed && (data.chainCheck || data.apiCheck)) && (
                                    <div className={ data.kyc ? "status green" : "status red" }>{ data.kyc ? <i className="fal fa-check-circle"></i> : <i className="fal fa-times-circle"></i> } { data.kyc ? "Verified" : "Not Verified" }</div>
                                )
                            }

                            {
                                (!data.chainCheck && !data.apiCheck) && (
                                    <div className="status amber"><i className="fal fa-question-circle"></i> Not Checked</div>
                                )
                            }

                            {
                                (data.chainCheck || data.apiCheck) && (
                                    <>
                                        <div className="subtitle">Features</div>
                                        <div className="limits">
                                            <div className="limit"><i className="fal fa-check-circle"></i> Buy Listings</div>
                                            <div className="limit"><i className="fal fa-check-circle"></i> Create Listings</div>
                                            <div className="limit">{ data.kyc ? <i className="fal fa-check-circle"></i> : <i className="fal fa-times-circle"></i> } Bridge Crypto</div>
                                            <div className="limit">{ data.kyc ? <i className="fal fa-check-circle"></i> : <i className="fal fa-times-circle"></i> } Earn Cashback</div>
                                            <div className="limit">{ data.kyc ? <i className="fal fa-check-circle"></i> : <i className="fal fa-times-circle"></i> } Arbitrate Disputes</div>
                                            <div className="limit">{ data.kyc ? <i className="fal fa-check-circle"></i> : <i className="fal fa-times-circle"></i> } Governance Council</div>
                                            <div className="limit"><i className="fal fa-check-circle"></i> Trade ({ data.kyc ? "Unlimited" : "$5,000 / day" })</div>
                                        </div>
                                    </>
                                )
                            }

                            {
                                (!data.kyc && !data.pending && (data.chainCheck || data.apiCheck)) && (
                                    <form onSubmit={ handleKYC }>
                                        <button type="submit" className="submit verify" disabled={ data.kyc ? true : false }>{ data.apiCheck ? textStrings["begin_verification"]?.[state.language] : textStrings["check_status"]?.[state.language] }</button>
                                    </form>
                                )
                            }
                        </section>

                        {
                            (state.w3aConnected) && (
                                <section className="private-key">
                                    <div className="content-title-area">
                                        <div className="content-title"><i className="fal fa-key"></i> { textStrings["account_private_key"]?.[state.language] }</div>
                                    </div>

                                    <form onSubmit={ handleExportPK }>
                                        <button type="submit" className="submit export">{ textStrings["export_pk"]?.[state.language] }</button>
                                    </form>
                                </section>
                            )
                        }

                        <section className="sessions">
                            <div className="content-title-area">
                                <div className="content-title divider"><i className="fal fa-phone-laptop"></i> { textStrings["account_sessions"][state.language] }</div>
                            </div>

                            <ul className="session-list">
                                {
                                    data.sessions.map((session, index) => (
                                        <li key={ index } className="session" data-id={ session._id }>
                                            <i className={ "device-type fal " + session.type }></i>
                                            <div className="device-agent">{ session.agent.replaceAll('"', '') }</div>
                                            <div className="device-created">{ "Created " + new Date(session.createdAt).toLocaleDateString() }</div>
                                            <div className="device-last-used">{ "Last Accessed " + new Date(session.lastUsed).toLocaleDateString() }</div>
                                            <i className="device-terminate fal fa-times" onClick={ handleTerminate }></i>
                                        </li>
                                    ))
                                }
                            </ul>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Settings;
